/* ------------------------------------------------------------------------------------------ 
      ENGLISH TRANSLATIONS
------------------------------------------------------------------------------------------ */

export const polishTranslation = {
    recentlyAdded: 'Ostatnio dodane',
    topTrending: 'Najpopularniejsze',
    featuredItems: 'Nasze polecane pliki',
    noCategoryMatch: 'Brak plików pasujących do tej kategorii',
    noReviewsYet: 'Brak opinii',
    accessNow: 'Dostęp teraz',
    downloadNow: 'Pobierz teraz',
    accessFileFor: 'Dostęp do tego pliku tylko za',
    youHaveAccess: 'Masz już dostęp do tego pliku',
    viewScreenshots: 'Zobacz zrzuty ekranu',
    promoted: 'Promowane',
    addToList: 'Dodaj do mojej listy',
    inList: 'Na Twojej liście',
    fileReviews: 'Opinie o pliku',
    fileGallery: 'Galeria plików',
    pending: 'Oczekujące',
    pendingMsg: 'Ten plik oczekuje na zatwierdzenie przez administratora',
    from: 'Od',
    ratings: 'Oceny',
    promoteThisFile: 'Promuj ten plik',
    promoteFor: 'Promuj za',
    youHaveReview: 'Już oceniłeś ten plik',
    rateThisFile: 'Oceń ten plik',
    fileHasNoReviews: 'Ten plik nie ma jeszcze żadnych opinii',
    discoverAll: 'Odkryj wszystkie pliki',
    filterByCategory: 'Filtruj według kategorii',
    sortByDate: 'Sortuj według daty',
    sortByPrice: 'Sortuj według ceny',
    loadMore: 'Załaduj więcej',
    select: 'Wybierz',
    all: 'Wszystko',
    noMatches: 'Brak pasujących plików',
    newest: 'Najnowsze jako pierwsze',
    oldest: 'Najstarsze jako pierwsze',
    highPrice: 'Cena: Od najwyższej do najniższej',
    lowPrice: 'Cena: Od najniższej do najwyższej',
    headerLinks: 'Linki',
    headerDiscover: 'Odkrywaj',
    headerActivity: 'Aktywność',
    headerFaqs: 'FAQ',
    headerContactUs: 'Skontaktuj się z nami',
    trackTransaction: 'Śledź transakcję',
    headerMyAccount: 'Moje konto',
    headerMyWishList: 'Moja lista życzeń',
    headerAdminPanel: 'Panel administracyjny',
    headerAdminOnly: 'Tylko dla administratora',
    weAreSocial: 'Jesteśmy na mediach społecznościowych',
    createdBy: 'Stworzone przez',
    copyRights: 'Wszelkie prawa zastrzeżone',
    activityHeading: 'Aktywność',
    tableActivityHeading: 'Najnowsze aktywności',
    tableNoRecords: 'Brak dostępnych aktywności do wyświetlenia',
    tableUser: 'Użytkownik',
    tableTime: 'Czas',
    tableAction: 'Akcja',
    tableMintFile: 'Dodany plik',
    tableApproveMint: 'Zatwierdzony plik',
    tableBuyFile: 'Zakupiony plik',
    tableWishList: 'Dodany plik do listy życzeń',
    tableNewSubscriber: 'Nowy subskrybent',
    tablePromote: 'Plik został dodany do promocji',
    contactPageHeading: 'Skontaktuj się z nami w sprawie dowolnego problemu',
    contactPageSubHeading:
        'Wypełnij formularz z tym, czego potrzebujesz, a skontaktujemy się z Tobą drogą mailową w ciągu 24 godzin',
    followUs: 'Obserwuj nas, aby być na bieżąco.',
    yourNameLabel: 'Twoje imię',
    yourNamePlaceholder: 'Wprowadź swoje imię',
    yourNameErrorMsg: 'Proszę podać swoje imię',
    yourEmail: 'Twój adres e-mail',
    yourEmailPlaceholder: 'Wprowadź swój adres e-mail',
    yourEmailErrorMsg: 'Proszę podać poprawny adres e-mail',
    yourEmailInvalidPattern: 'Nieprawidłowy adres e-mail',
    yourMessage: 'Twoja wiadomość',
    yourMessagePlaceholder: 'Twoja wiadomość dotycząca jakiejkolwiek prośby',
    yourMessageErrorMsg: 'Proszę wprowadzić swoją wiadomość',
    submitRequest: 'Prześlij swoją prośbę',
    submitting: 'Przesyłanie...',
    successSubmitHeading: 'Dziękujemy',
    successSubmitMsg: 'Odezwiemy się do Ciebie jak najszybciej',
    returnHome: 'Powrót na stronę główną',
    utility: 'Użyteczność',
    title: 'Tytuł',
    titleErrorMsg: 'Proszę wprowadzić tytuł wideo',
    titlePlaceHolder: 'Wprowadź tytuł produktu',
    description: 'Opis',
    descriptionErrorMsg: 'Proszę wprowadzić opis pliku',
    descriptionPlaceholder: 'Dodaj krótki opis swojego pliku',
    category: 'Kategoria',
    categoryPlaceholder: 'Wybierz kategorię',
    compatibleWith: 'Kompatybilny z',
    compatibleWithPlaceholder: 'Wybierz kompatybilność z systemem operacyjnym',
    compatibleWithErrorMsg: 'Proszę wybrać z jakim systemem operacyjnym jest kompatybilne oprogramowanie',
    price: 'Cena',
    pricePlaceholder: 'Wprowadź pożądaną cenę pliku w',
    priceErrorMsg: 'Proszę podać cenę pliku',
    uploadFile: 'Prześlij swój plik',
    uploadFileErrorMsg: 'Proszę przesłać swój plik',
    uploadFileThumb: 'Prześlij miniaturkę pliku',
    uploadFileThumbErrorMsg: 'Proszę przesłać miniaturkę pliku',
    uploadFileScreenshots: 'Prześlij zrzuty ekranu pliku',
    uploadFileScreenshotsErrorMsg: 'Proszę przesłać zrzuty ekranu pliku',
    wishlistPageHeading: 'Moja lista życzeń',
    myAccountHeading: 'Moje konto',
    sellNewProduct: 'Sprzedaj nowy produkt',
    salesProfits: 'Zyski ze sprzedaży',
    noProfitsYet: 'Nie masz jeszcze żadnych zysków',
    claimProfits: 'Odbierz zyski',
    accTableFile: 'Plik',
    accTableCreator: 'Twórca',
    accTableCreatedAt: 'Utworzono',
    filesIHaveUploaded: 'Pliki, które przesłałem',
    accNoUploadedFiles: 'Brak przesłanych plików do wyświetlenia',
    accPendingFiles: 'Moje oczekujące pliki',
    accNoPendingFiles: 'Brak oczekujących plików do wyświetlenia',
    accPurchasedFiles: 'Moje zakupione pliki',
    accNoPurchasedFiles: 'Brak zakupionych plików do wyświetlenia',
    adminPanel: 'Panel administracyjny',
    appProfits: 'Zyski z aplikacji',
    approveSelected: 'Zatwierdź wybrane',
    blockSelected: 'Zablokuj wybrane',
    noFilesToDisplay: 'Brak plików do wyświetlenia',
    allPendingFiles: 'Wszystkie oczekujące pliki',
    status: 'Status',
    active: 'Aktywny',
    setPromotionPrice: 'Ustaw cenę promocyjną',
    currentPriceIs: 'Aktualna cena wynosi',
    promotionPrice: 'Cena promocyjna',
    promotionPricePlaceholder: 'Wprowadź cenę promocyjną',
    promotionPriceErrorMsg: 'Proszę podać cenę promocyjną',
    setPrice: 'Ustaw cenę',
    transferContractOwnership: 'Przenieś właścicielstwo kontraktów',
    newOwner: 'Nowy właściciel',
    newOwnerPlaceholder: 'Wprowadź adres nowego właściciela',
    newOwnerErrorMsg: 'Nieprawidłowy adres! Sprawdź wprowadzony adres',
    transfer: 'Przenieś',
    newOwnerSuccessHeading: 'Nie jesteś już właścicielem tego kontraktu',
    newOwnerSuccessText:
        'Właścicielstwo zostało przekazane innemu kontu, nie masz już dostępu do tej strony. Powodzenia',
    updatePrice: 'Aktualizuj cenę',
    newPrice: 'Nowa cena',
    updateProductPrice: 'Aktualizuj cenę produktu',
    equals: 'Równa się',
    txLoadingHeading: 'To zwykle zajmuje trochę czasu',
    txLoadingMsg: 'Przetwarzamy Twoją transakcję, prosimy nie odświeżać przeglądarki',
    txLoadingSmText: 'Przetwarzanie transakcji',
    rating: 'Ocena',
    reason: 'Powód',
    enterRatingReason: 'Proszę podać powód oceny',
    reasonPleaceholder: "'np. Jakość kodu'",
    message: 'Wiadomość',
    messagePlaceholder: 'Wprowadź wiadomość z oceną',
    ratingMsgError: 'Proszę wprowadzić wiadomość z oceną',
    submitRating: 'Prześlij ocenę',
    close: 'Zamknij',
    uploadingYourAsset: 'Przesyłanie twoich zasobów',
    ipfsMsg: 'To może chwilę potrwać, aż zapiszemy twoje zasoby w IFPS...',
    cover: 'Okładka',
    avatar: 'Awatar',
    screenshot: 'Zrzut ekranu',
    appRunOn: 'Ta aplikacja jest skonfigurowana do działania na',
    connectToNetwork: 'Proszę połączyć się z tą siecią',
    switchNetwork: 'Zmień sieć',
    oneOfYourItem: 'Jeden z Twoich elementów',
    err: 'Ups! Wystąpił błąd',
    youHaveAccessNow: 'Świetnie! Masz teraz dostęp',
    fillTheFields: 'Proszę wypełnić pola, aby móc je wysłać',
    addedToWatchlist: 'Świetnie! Dodałeś ten plik do listy obserwowanych',
    claimedProfits: 'Świetnie! Odebrałeś swoje zyski',
    filePriceUpdated: 'Świetnie! Cena Twojego pliku została zaktualizowana',
    approvedSelectedFiles: 'Świetnie! Zaakceptowałeś wybrane pliki',
    blockedSelectedFiles: 'Świetnie! Zablokowałeś wybrane pliki',
    promotionPriceAdded: 'Świetnie! Cena promocyjna została ustawiona',
    ownerShipChanged: 'Świetnie! Własność została przekazana na inne konto',
    fileIsPromoted: 'Świetnie! Twój plik został promowany',
    fileUploaded: 'Świetnie! Pomyślnie przesłano plik',
    filesTooLarge: 'Pliki są za duże',
    buy: 'Kup',
    charts: 'Wykresy',
    audit: 'Audyt',
    lottery: 'Loteria',
    frequAskedQuestions: 'Najczęściej zadawane pytania',
    whoAreWe: 'Kim jesteśmy?',
    whoWeAreAnsw:
        'Jesteśmy zespołem programistów Web3, którzy wdrażają to, co będzie przyszłością internetu, blockchaina i decentralizacji. Ten projekt umożliwia każdemu użytkownikowi bezpieczną sprzedaż plików bezpośrednio z jego komputera lub smartfona, bez pośredników.',
    maxTotalSupply: 'Maksymalna/Łączna podaż',
    assetPipeLine: 'i rurociąg zasobów dla blockchainów.',
    tax: 'Podatek',
    sell: 'Sprzedaj',
    holderRewards: 'Nagrody dla posiadaczy',
    marketing: 'Marketing',
    whitePaper: 'Whitepaper',
    trade: 'Handel',
    official: 'Oficjalne',
    clickHere: 'Kliknij tutaj',
    difiDetailsQuest: 'Szczegóły i linki DIFI',
    functionsOfDifiMarket: 'Funkcje rynku DIFI:',
    onDifiMarketYouCan: 'Na rynku DIFI możesz:',
    difiMarketBullet1: 'Sprzedawać swoje pliki, programy i każdy rodzaj pliku cyfrowego.',
    difiMarketBullet2: 'Kupować pliki innych użytkowników.',
    difiMarketBullet3: 'Zostawiać opinie po zakupie.',
    difiMarketBullet4: 'Wypłacać swoje DIFI ze sprzedaży.',
    whatWalletSupportedQues: 'Jakie portfele są obsługiwane?',
    whatWalletSupportedAnsw:
        'Digital Files korzysta z WalletConnect i obsługuje dziesiątki różnych portfeli do interakcji ze sklepem.',
    whatAreCommissionQuest: 'Jakie są prowizje?',
    fees: 'Opłaty',
    onEachSale: 'przy każdej sprzedaży',
    whatAreCommissionAnsw:
        'Do każdej sprzedanej pozycji stosowany jest podatek w wysokości 1%. System korzysta z blockchaina i IPFS, a 1% podatku od każdej sprzedaży pozwala nam utrzymać system w doskonałej kondycji.',
    uploadLimit: 'Limit przesyłania plików?',
    uploadLimitAnsw1: 'Maksymalny limit przesyłania dla każdego pliku wynosi około 100 MB.',
    uploadLimitAnsw2: 'Nie ma ograniczeń co do liczby plików, które użytkownik może przesłać i sprzedawać.',
    whatDoIBuyWith: 'Czym mogę kupić pliki?',
    whatDoIBuyWithAnsw:
        'Pliki można kupować za pomocą tokena DIFI, potrzebne są również niektóre BNB do zarządzania transakcjami, ponieważ jest to waluta natywna.',
    canIChangePrice: 'Czy mogę zmienić cenę pliku?',
    canIChangePriceAnsw:
        'Obecnie nie jest możliwe zmienienie ceny pliku, należy odpowiednio obliczyć cenę w oparciu o cenę DIFI podczas sprzedaży pliku.',
    moreQuestions: 'Więcej pytań?',
    ifYouCantFindAnsw: 'Jeśli nie znajdziesz odpowiedzi tutaj',
    getInTouch: 'skontaktuj się z nami',
    weWillHelp: 'Chętnie pomożemy',
    education: 'Materiały edukacyjne',
    eBooks: 'eBooki',
    gDesign: 'Grafika',
    os: 'Systemy operacyjne',
    programming: 'Programowanie',
    antiVirus: 'Antywirusy i zabezpieczenia',
    db: 'Bazy danych',
    mutimedia: 'Multimedia',
    gaming: 'Gry',
    openSource: 'Open Source',
    adult: 'Dla dorosłych 18+',

    // Latest Translations keys --------------
    faqs: 'FAQs',
    contactUs: 'Skontaktuj się z nami',
    aboutUs: 'O nas',
    activity: 'Aktywność',
    aboutUsHeading: 'O nas',
    wordsAboutUsHeading: 'Kilka słów o nas',
    appLauncingTime: 'DIFI uruchomiony 1 kwietnia 2023',
    aboutWord1: 'Pierwszy rynek plików cyfrowych stworzony na blockchainie w web3, zasilany przez Binance Smart Chain.',
    aboutWord2:
        'Kupuj, sprzedawaj i promuj swoje cyfrowe twórcze dzieła w najłatwiejszy i najbezpieczniejszy sposób na rynku. Bez rejestracji, bez udziału stron trzecich. To jest decentralizacja DIFI.',
    creator: 'Twórca',
    topFeatureHeading: 'Najważniejsze funkcje',
    toPFeatureSubheading: 'Najważniejsze 3 funkcje na rynku DIFI, które nie mają sobie równych!',
    decentralized: 'Zdecentralizowany',
    decentralizedDescription:
        'Rynek DIFI jest w 100% zdecentralizowany, wszystko jest przechowywane na Binance Smart Chain dla maksymalnego bezpieczeństwa, Użytkownicy korzystają, łącząc swoje portfele bez zewnętrznego zarządzania.',
    hackerFreeHeading: 'Bezpieczny przed hakerami',
    hackerFreeDescription:
        'System nie przechowuje żadnych danych na serwerze internetowym, nie ma ryzyka utraty danych, użytkownicy korzystają z portfela podczas połączenia, nie można manipulować żadnymi danymi, a administratorzy nie mają dostępu do środków użytkowników.',
    lowFeesHeading: 'Niskie opłaty',
    lowFeesDescription:
        'Prawdopodobnie najniższe opłaty na świecie, tylko 1% prowizji od sprzedaży, brak ukrytych opłat, użytkownicy mogą wypłacać DIFI ze swojego konta w dowolnym momencie, bez oczekiwania ani potwierdzeń, użytkownicy płacą w DIFI tylko w momencie składania zamówienia.',
    aboutUsQuote: '"Z DIFI Market jesteśmy już w przyszłości, przede wszystkim."',
    token: 'Token',
    founder: 'Założyciel',
    digitalFilesMarket: 'Rynek plików cyfrowych',
    subscribeThanks: 'Dziękujemy za zapisanie się',
    toastError: 'Ups! Coś poszło nie tak!',
    resources: 'Zasoby',
    newsletterSignup: 'Zapisz się na newsletter',
    newsletterText: 'Zapisz się na nasz newsletter i bądź na bieżąco',
    newsletterErrMsg: 'Proszę podać swój adres e-mail',
    newsletterPlaceholder: 'Dodaj swój adres e-mail',
    sitemap: 'Mapa witryny',
    bestSellers: 'Najlepiej sprzedające się',
    topRated: 'Najwyżej oceniane',
    home: 'Strona główna',
    files: 'Pliki',
    resetFilters: 'Resetuj filtry',
    searchForFiles: 'Szukaj produktów',
    noSearchResults: 'Nie ma produktów odpowiadających Twojemu wyszukiwaniu',
    noFilesAtTheMoment: 'Obecnie nie ma dostępnych plików.',
    emptyHere: 'Jest tu pusto',
    poromotionEndsAt: 'Promocja kończy się o',
    sortByRate: 'Sortuj według oceny',
    shopBrandDescription: 'Zdecentralizowany sklep z cyfrowymi plikami Web3',
    uploadFileNav: 'Prześlij plik',
    account: 'Konto',
    wishlist: 'Lista życzeń',
    upload: 'Prześlij',
    menulegal: 'Prawne',
    menuprivacy: 'Polityka prywatności',
    menutos: 'Warunki korzystania',
    menulegaopinion: 'Opinia prawna',
    share: 'Udział',
};
