import React, { useReducer } from 'react';
import { uniqueNamesGenerator, starWars } from 'unique-names-generator';
import { readContract } from '@wagmi/core';
import Web3 from 'web3';

import FilesContext from './files-context';

const defaultFilesState = {
    files: [],
    filesWithBuyers: [],
    activities: [],
    contract: null,
    filesContractAbi: null,
    owner: '',
    ratingModalState: false,
    mintUploadProgress: 0,
    previewModal: false,
    preview: {},
    ratingModalSrc: {},
    buyers: [],
    transactionLoading: false,
    uploadingProgress: false,
    userVideos: [],
    appProfits: '0',
    promotionPrice: 0,
    tokenInUSD: 0,
    usdToken: 0,
    lang: JSON.parse(localStorage.getItem('digital_files_lang')) || { code: 'en', key: 'English' },
};

const filesReducer = (state, action) => {
    if (action.type === 'GET_FILES') {
        return {
            ...state,
            files: action?.files?.map((file) => {
                return {
                    id: Number(file[0]),
                    metadata: {
                        title: file[1][0],
                        description: file[1][1],
                        category: file[1][2],
                        type: file[1][3],
                        file: file[1][4],
                        screenshots: file[1][5],
                        compatibility: `${file[1][6]?.map((os, i) =>
                            i < file[1][6]?.length - 1 ? `${os}` : os
                        )}`?.replaceAll(',', ', '),
                        thumbnail: file[1][7],
                    },
                    price: Number(
                        Web3.utils.fromWei(Number(file[2]).toLocaleString('fullwide', { useGrouping: false }), 'ether')
                    ),
                    usdPrice: Number(
                        Web3.utils.fromWei(Number(file[2]).toLocaleString('fullwide', { useGrouping: false }), 'ether')
                    ),
                    tokenPrice: Math.round(
                        Number(
                            Web3.utils.fromWei(
                                Number(file[2]).toLocaleString('fullwide', { useGrouping: false }),
                                'ether'
                            )
                        ) / usdPrice
                    ),
                    buyers: [],
                    creator: file[3],
                    userGenName: uniqueNamesGenerator({
                        dictionaries: [starWars],
                        seed: file[3],
                    }).replace('_', ' '),
                    approved: file[5],
                    createdAt: Number(file[6]) * 1000,
                    promoted: file[7] && Number(file[9]) * 1000 > new Date().getTime(),
                    reviews: file[8]?.map((rating) => ({
                        account: rating[0],
                        accountGenName: uniqueNamesGenerator({
                            dictionaries: [starWars],
                            seed: rating[0],
                        }).replace('_', ' '),
                        stars: Number(rating[2]),
                        message: rating[1],
                        reason: rating[3],
                    })),
                    promotingExp: Number(file[9]) * 1000,
                    eligibleForPromote: new Date().getTime() > Number(file[9]) * 1000,
                };
            }),
        };
    }
    if (action.type === 'GET_BUYERS') {
        return {
            ...state,
            buyers: action.buyers.map((buyer) => {
                return buyer[0];
            }),
        };
    }
    if (action.type === 'GET_FILES_BUYERS') {
        return {
            ...state,
            filesWithBuyers: action.filesWithBuyers,
        };
    }
    if (action.type === 'GET_ABI') {
        return {
            ...state,
            filesContractAbi: action.filesContractAbi,
        };
    }
    if (action.type === 'CONTRACT') {
        return {
            ...state,
            contract: action.contract,
        };
    }
    if (action.type === 'LOADING') {
        return {
            ...state,
            transactionLoading: action.loading,
        };
    }
    if (action.type === 'UPLOADING_PROGRESS') {
        return {
            ...state,
            uploadingProgress: action.loading,
        };
    }
    if (action.type === 'SET_PREVIEW_MODAL') {
        return {
            ...state,
            previewModal: action.status,
        };
    }
    if (action.type === 'SET_RATING_MODAL_STATE') {
        return {
            ...state,
            ratingModalState: action.state,
        };
    }
    if (action.type === 'SET_RATING_MODAL_SRC') {
        return {
            ...state,
            ratingModalSrc: {
                id: action.src.id,
                title: action.src.title,
            },
        };
    }
    if (action.type === 'GET_PROFITS') {
        return {
            ...state,
            appProfits: Number(
                Web3.utils.fromWei(Number(action.profits).toLocaleString('fullwide', { useGrouping: false }), 'ether')
            ),
        };
    }
    if (action.type === 'GET_UPLOAD_PROGRESS') {
        return {
            ...state,
            mintUploadProgress: action.progress,
        };
    }
    if (action.type === 'LOAD_ACTIVITIES') {
        return {
            ...state,
            activities: action.activities.map((el) => {
                return {
                    user: el[0],
                    time: Number(el[2]) * 1000,
                    action: el[3],
                };
            }),
        };
    }
    if (action.type === 'SET_PRIVEW_SRC') {
        return {
            ...state,
            preview: {
                src: action.preview.src,
                id: action.preview.id,
                buyers: action.preview.buyers,
                price: action.preview.price,
                creator: action.preview.creator,
            },
        };
    }
    if (action.type === 'GET_OWNER') {
        return {
            ...state,
            owner: action.owner,
        };
    }
    if (action.type === 'SWITCH_LANGUAGE') {
        return {
            ...state,
            lang: action.lang,
        };
    }
    if (action.type === 'LOAD_PROMOTION_PRICE') {
        return {
            ...state,
            promotionPrice: Number(
                Web3.utils.fromWei(
                    Number(action.promotionPrice).toLocaleString('fullwide', { useGrouping: false }),
                    'ether'
                )
            ),
        };
    }
    if (action.type === 'GET_TOKEN_IN_USD') {
        return {
            ...state,
            tokenInUSD: action.payload.tokenInUSD,
            usdToken: action.payload.usdToken,
        };
    }

    return defaultFilesState;
};

let usdPrice = 0;
let usdToken = 0;

const FilesProvider = (props) => {
    const [filesState, dispatchFilesAction] = useReducer(filesReducer, defaultFilesState);

    const setTransactionLoadingHandler = (loading) => {
        dispatchFilesAction({ type: 'LOADING', loading: loading });
    };

    const setUploadingProgressHandler = (loading) => {
        dispatchFilesAction({ type: 'UPLOADING_PROGRESS', loading: loading });
    };

    const setPreviewModalHandler = (status) => {
        dispatchFilesAction({ type: 'SET_PREVIEW_MODAL', status: status });
    };

    const setPreviewSrcHandler = (src, buyers, id, price, creator) => {
        dispatchFilesAction({ type: 'SET_PRIVEW_SRC', preview: { src, id, buyers, price, creator } });
    };

    const setRatingModalStateHandler = (state) => {
        dispatchFilesAction({ type: 'SET_RATING_MODAL_STATE', state: state });
    };
    const setRatingModalSrcHandler = (id, title, rating, ratingReason, ratingMsg) => {
        dispatchFilesAction({
            type: 'SET_RATING_MODAL_SRC',
            src: { id: id, title: title },
        });
    };

    const getContractAbiHandler = (filesContractAbi) => {
        dispatchFilesAction({ type: 'GET_ABI', filesContractAbi: filesContractAbi });
    };

    const switchLanguageHandler = (lang) => {
        dispatchFilesAction({ type: 'SWITCH_LANGUAGE', lang: lang });
        localStorage.setItem('digital_files_lang', JSON.stringify(lang));
        return lang;
    };

    const loadContractHandler = async (contract) => {
        dispatchFilesAction({ type: 'CONTRACT', contract: contract });
        return contract;
    };

    const loadFilesHandler = async (contract, abi) => {
        const files = await readContract({
            address: contract?.address,
            abi: abi,
            functionName: 'getFiles',
        });

        dispatchFilesAction({ type: 'GET_FILES', files: files });

        return files;
    };

    const loadFileBuyersHandler = async (contract, id, abi) => {
        const buyers = await readContract({
            address: contract.address,
            abi: abi,
            functionName: 'getSubscribers',
            args: [id],
        });
        dispatchFilesAction({ type: 'GET_BUYERS', buyers: buyers });
        return buyers;
    };

    const loadFileBuyersWithBuyersHandler = async (contract, abi) => {
        const files = await readContract({
            address: contract?.address,
            abi: abi,
            functionName: 'getFiles',
        });

        const formattedFiles = files?.map((file) => {
            return {
                id: Number(file[0]),
                metadata: {
                    title: file[1][0],
                    description: file[1][1],
                    category: file[1][2],
                    type: file[1][3],
                    file: file[1][4],
                    screenshots: file[1][5],
                    compatibility: `${file[1][6]?.map((os, i) =>
                        i < file[1][6]?.length - 1 ? `${os}` : os
                    )}`?.replaceAll(',', ', '),
                    thumbnail: file[1][7],
                },
                price: Number(
                    Web3.utils.fromWei(Number(file[2]).toLocaleString('fullwide', { useGrouping: false }), 'ether')
                ),
                usdPrice: Number(
                    Web3.utils.fromWei(Number(file[2]).toLocaleString('fullwide', { useGrouping: false }), 'ether')
                ),
                tokenPrice: Math.round(
                    Number(
                        Web3.utils.fromWei(Number(file[2]).toLocaleString('fullwide', { useGrouping: false }), 'ether')
                    ) / usdPrice
                ),
                creator: file[3],
                userGenName: uniqueNamesGenerator({
                    dictionaries: [starWars],
                    seed: file[3],
                }).replace('_', ' '),
                approved: file[5],
                createdAt: Number(file[6]) * 1000,
                promoted: file[7] && Number(file[9]) * 1000 > new Date().getTime(),
                reviews: file[8]?.map((rating) => ({
                    account: rating[0],
                    accountGenName: uniqueNamesGenerator({
                        dictionaries: [starWars],
                        seed: rating[0],
                    }).replace('_', ' '),
                    stars: Number(rating[2]),
                    message: rating[1],
                    reason: rating[3],
                })),
                promotingExp: Number(file[9]) * 1000,
                eligibleForPromote: new Date().getTime() > Number(file[9]) * 1000,
            };
        });

        let filesWithBuyers = [];

        if (formattedFiles) {
            for (let i = 0; i < files.length; i++) {
                const subs = await readContract({
                    address: contract.address,
                    abi: abi,
                    functionName: 'getSubscribers',
                    args: [formattedFiles[i]?.id],
                });
                filesWithBuyers.push({
                    ...formattedFiles[i],
                    buyers: [...subs?.map((sub) => sub[0])],
                });
            }
        }

        dispatchFilesAction({ type: 'GET_FILES_BUYERS', filesWithBuyers: filesWithBuyers });
        return filesWithBuyers;
    };

    const loadAppOwnerHandler = async (contract, abi) => {
        const owner = await readContract({
            address: contract.address,
            abi: abi,
            functionName: 'owner',
        });

        dispatchFilesAction({ type: 'GET_OWNER', owner: owner });
        return owner;
    };

    const loadAppProfitsHandler = async (contract, address, abi) => {
        const profits = await readContract({
            address: contract.address,
            abi: abi,
            functionName: 'user_funds',
            args: [address],
        });

        dispatchFilesAction({ type: 'GET_PROFITS', profits: profits });

        return profits;
    };

    const loadMintUploadProgressHandler = (progress) => {
        dispatchFilesAction({ type: 'GET_MINT_PROGRESS', progress: progress });
        return progress;
    };

    const loadActivitiesHandler = async (contract, abi) => {
        const activities = await readContract({
            address: contract.address,
            abi: abi,
            functionName: 'get_activities',
        });

        dispatchFilesAction({ type: 'LOAD_ACTIVITIES', activities: activities });
        return activities;
    };

    const loadPromotionPriceHandler = async (contract, abi) => {
        const promotionPrice = await readContract({
            address: contract.address,
            abi: abi,
            functionName: 'promotionPrice',
        });

        dispatchFilesAction({ type: 'LOAD_PROMOTION_PRICE', promotionPrice: promotionPrice });

        return promotionPrice;
    };

    const loadPaymentTokenPriceInUSDHandler = async (tokenId) => {
        try {
            const res =
                await fetch(`https://api.coingecko.com/api/v3/simple/price?ids=${tokenId?.toLowerCase()}&vs_currencies=usd
                `);
            const data = await res.json();
            const tokenInUSD = data[`${tokenId?.toLowerCase()}`]?.usd;
            usdPrice = tokenInUSD;
            usdToken = parseFloat((1 / tokenInUSD).toFixed(2));
            console.log(usdToken);

            dispatchFilesAction({
                type: 'GET_TOKEN_IN_USD',
                payload: { tokenInUSD: parseFloat(tokenInUSD), usdToken: usdToken },
            });
            return { tokenInUSD, usdToken };
        } catch (error) {
            console.log(error);
        }
    };

    const filesContext = {
        files: filesState.files,
        filesWithBuyers: filesState.filesWithBuyers,
        contract: filesState.contract,
        filesContractAbi: filesState.filesContractAbi,
        buyers: filesState.buyers,
        owner: filesState.owner,
        appProfits: filesState.appProfits,
        previewModal: filesState.previewModal,
        mintUploadProgress: filesState.mintUploadProgress,
        preview: filesState.preview,
        ratingModalSrc: filesState.ratingModalSrc,
        ratingModalState: filesState.ratingModalState,
        transactionLoading: filesState.transactionLoading,
        uploadingProgress: filesState.uploadingProgress,
        activities: filesState.activities,
        promotionPrice: filesState.promotionPrice,
        lang: filesState.lang,
        tokenInUSD: filesState.tokenInUSD,
        usdToken: filesState.usdToken,
        loadContract: loadContractHandler,
        getContractAbi: getContractAbiHandler,
        loadFiles: loadFilesHandler,
        loadFileBuyersWithBuyers: loadFileBuyersWithBuyersHandler,
        loadFileBuyers: loadFileBuyersHandler,
        loadAppOwner: loadAppOwnerHandler,
        setTransactionLoading: setTransactionLoadingHandler,
        setPreviewModal: setPreviewModalHandler,
        loadMintUploadProgress: loadMintUploadProgressHandler,
        setPreviewSrc: setPreviewSrcHandler,
        loadAppProfits: loadAppProfitsHandler,
        setUploadingProgress: setUploadingProgressHandler,
        loadActivities: loadActivitiesHandler,
        setRatingModalState: setRatingModalStateHandler,
        setRatingModalSrc: setRatingModalSrcHandler,
        loadPromotionPrice: loadPromotionPriceHandler,
        switchLanguage: switchLanguageHandler,
        loadPaymentTokenPriceInUSD: loadPaymentTokenPriceInUSDHandler,
    };

    return <FilesContext.Provider value={filesContext}>{props.children}</FilesContext.Provider>;
};

export default FilesProvider;
