/* ------------------------------------------------------------------------------------------ 
      ITALIAN TRANSLATIONS
------------------------------------------------------------------------------------------ */

export const italianTranslation = {
    recentlyAdded: 'Aggiunti di recente',
    topTrending: 'I più popolari',
    featuredItems: 'I nostri File in primo piano',
    noCategoryMatch: 'Non ci sono file corrispondenti a questa categoria',
    noReviewsYet: 'Ancora nessuna recensione',
    accessNow: 'Accedi ora',
    downloadNow: 'Scarica ora',
    accessFileFor: 'Accedi a questo file per soli',
    youHaveAccess: 'Hai già accesso a questo file',
    viewScreenshots: 'Visualizza screenshot',
    promoted: 'Promosso',
    addToList: 'Aggiungi alla mia lista',
    inList: 'Nella tua lista',
    fileReviews: 'Recensioni del file',
    fileGallery: 'Galleria del file',
    pending: 'In attesa',
    pendingMsg: "Questo file è in attesa di approvazione da parte dell'amministratore",
    from: 'Da',
    ratings: 'Valutazioni',
    promoteThisFile: 'Promuovi questo file',
    promoteFor: 'Promuovi per',
    youHaveReview: 'Hai già recensito questo file',
    rateThisFile: 'Vota questo file',
    fileHasNoReviews: 'Questo file non ha ancora recensioni',
    discoverAll: 'Scopri tutti i file',
    filterByCategory: 'Filtra per categoria',
    sortByDate: 'Ordina per data',
    sortByPrice: 'Ordina per prezzo',
    loadMore: 'Carica altro',
    select: 'Seleziona',
    all: 'Tutti',
    noMatches: 'Nessun file corrisponde a questo filtro',
    newest: 'Primi più recenti',
    oldest: 'Primi più vecchi',
    highPrice: 'Prezzo: Alto a Basso',
    lowPrice: 'Prezzo: Basso ad Alto',
    headerLinks: 'Collegamenti',
    headerDiscover: 'Scopri',
    headerActivity: 'Attività',
    headerFaqs: 'Domande frequenti',
    headerContactUs: 'Contattaci',
    trackTransaction: 'Traccia transazioni',
    headerMyAccount: 'Il mio account',
    headerMyWishList: 'La mia lista dei desideri',
    headerAdminPanel: 'Pannello di controllo amministratore',
    headerAdminOnly: 'Solo amministratori',
    weAreSocial: 'Siamo sui social',
    createdBy: 'Creato da',
    copyRights: 'Tutti i diritti riservati',
    activityHeading: 'Attività',
    tableActivityHeading: 'Ultime attività',
    tableNoRecords: 'Non ci sono ancora attività da visualizzare',
    tableUser: 'Utente',
    tableTime: 'Ora',
    tableAction: 'Azione',
    tableMintFile: 'File caricato',
    tableApproveMint: 'File approvato',
    tableBuyFile: 'File acquistato',
    tableWishList: 'File aggiunto alla lista dei desideri',
    tableNewSubscriber: 'Nuovo iscritto',
    tablePromote: 'Il file è stato aggiunto alla promozione',
    contactPageHeading: 'Contattaci per qualsiasi problema',
    contactPageSubHeading: 'Compila il modulo con ciò di cui hai bisogno e ti contatteremo via email entro 24 ore',
    followUs: 'Seguici per rimanere aggiornato.',
    yourNameLabel: 'Il tuo nome',
    yourNamePlaceholder: 'Inserisci il tuo nome',
    yourNameErrorMsg: 'Inserisci il tuo nome',
    yourEmail: 'La tua email',
    yourEmailPlaceholder: 'Inserisci il tuo indirizzo email',
    yourEmailErrorMsg: 'Inserisci un indirizzo email valido',
    yourEmailInvalidPattern: 'Indirizzo email non valido',
    yourMessage: 'Il tuo messaggio',
    yourMessagePlaceholder: 'Il tuo messaggio riguardo a una richiesta',
    yourMessageErrorMsg: 'Inserisci il tuo messaggio',
    submitRequest: 'Invia la tua richiesta',
    submitting: 'Invio in corso...',
    successSubmitHeading: 'Grazie',
    successSubmitMsg: 'Ti risponderemo il prima possibile',
    returnHome: 'Torna alla Home',
    utility: 'Utility',
    title: 'Titolo',
    titleErrorMsg: 'Inserisci il titolo del tuo video',
    titlePlaceHolder: 'Inserisci il titolo del tuo prodotto',
    description: 'Descrizione',
    descriptionErrorMsg: 'Inserisci la descrizione del tuo file',
    descriptionPlaceholder: 'Aggiungi una breve descrizione del tuo file',
    category: 'Categoria',
    categoryPlaceholder: 'Seleziona una categoria',
    compatibleWith: 'Compatibile con',
    compatibleWithPlaceholder: 'Seleziona la compatibilità con il sistema operativo',
    compatibleWithErrorMsg: 'Seleziona con quale sistema operativo è compatibile questo software',
    price: 'Prezzo',
    pricePlaceholder: 'Inserisci il prezzo desiderato del tuo file in',
    priceErrorMsg: 'Inserisci il prezzo del tuo file',
    uploadFile: 'Carica il tuo file',
    uploadFileErrorMsg: 'Carica il tuo file',
    uploadFileThumb: 'Carica la miniatura del tuo file',
    uploadFileThumbErrorMsg: 'Carica la miniatura del tuo file',
    uploadFileScreenshots: 'Carica gli screenshot del file',
    uploadFileScreenshotsErrorMsg: 'Carica gli screenshot del tuo file',
    wishlistPageHeading: 'La mia lista dei desideri',
    myAccountHeading: 'Il mio account',
    sellNewProduct: 'Vendi un nuovo prodotto',
    salesProfits: 'Profitti delle vendite',
    noProfitsYet: 'Non hai ancora guadagnato profitti',
    claimProfits: 'Richiedi profitti',
    accTableFile: 'File',
    accTableCreator: 'Creatore',
    accTableCreatedAt: 'Creato il',
    filesIHaveUploaded: 'File che ho caricato',
    accNoUploadedFiles: 'Non ci sono file caricati da mostrare',
    accPendingFiles: 'I miei file in sospeso',
    accNoPendingFiles: 'Non ci sono file in sospeso da mostrare',
    accPurchasedFiles: 'I miei file acquistati',
    accNoPurchasedFiles: 'Non ci sono file acquistati da mostrare',
    adminPanel: 'Pannello di amministrazione',
    appProfits: "Profitti dell'app",
    approveSelected: 'Approva selezionati',
    blockSelected: 'Blocca selezionati',
    noFilesToDisplay: 'Non ci sono file da mostrare',
    allPendingFiles: 'Tutti i file in sospeso',
    status: 'Stato',
    active: 'Attivo',
    setPromotionPrice: 'Imposta il prezzo promozionale',
    currentPriceIs: 'Il prezzo corrente è',
    promotionPrice: 'Prezzo promozionale',
    promotionPricePlaceholder: 'Inserisci il prezzo promozionale',
    promotionPriceErrorMsg: 'Inserisci il prezzo promozionale',
    setPrice: 'Imposta il prezzo',
    transferContractOwnership: 'Trasferisci la proprietà del contratto',
    newOwner: 'Nuovo proprietario',
    newOwnerPlaceholder: "Inserisci l'indirizzo del nuovo proprietario",
    newOwnerErrorMsg: "Indirizzo non valido! Controlla l'indirizzo che hai inserito",
    transfer: 'Trasferisci',
    newOwnerSuccessHeading: 'Non sei più il proprietario di questo contratto',
    newOwnerSuccessText:
        'La proprietà è stata trasferita a un altro account, non hai più accesso a questa pagina. Buona fortuna',
    updatePrice: 'Aggiorna prezzo',
    newPrice: 'Nuovo prezzo',
    updateProductPrice: 'Aggiorna prezzo prodotto',
    equals: 'Uguale a',
    txLoadingHeading: 'Questo richiede del tempo',
    txLoadingMsg: 'Stiamo elaborando la tua transazione, per favore non ricaricare la pagina',
    txLoadingSmText: 'Elaborazione transazione',
    rating: 'Valutazione',
    reason: 'Motivo',
    enterRatingReason: 'Inserisci il motivo della valutazione',
    reasonPleaceholder: "'es: Qualità del codice'",
    message: 'Messaggio',
    messagePlaceholder: 'Inserisci il tuo messaggio di recensione',
    ratingMsgError: 'Inserisci il messaggio di valutazione',
    submitRating: 'Invia la tua valutazione',
    close: 'Chiudi',
    uploadingYourAsset: 'Caricamento del tuo asset',
    ipfsMsg: 'Potrebbe volerci del tempo fino a quando salviamo i tuoi asset su IFPS...',
    cover: 'Copertina',
    avatar: 'Avatar',
    screenshot: 'Screenshot',
    appRunOn: 'Questa app è configurata per funzionare su',
    connectToNetwork: 'Per favore, connettiti a questa rete',
    switchNetwork: 'Cambia rete',
    oneOfYourItem: 'Uno dei tuoi articoli',
    err: 'Ops! Si è verificato un errore',
    youHaveAccessNow: 'Fantastico! Ora hai accesso',
    fillTheFields: 'Per favore, compila i campi per poter inviare',
    addedToWatchlist: 'Fantastico! Hai aggiunto questo File alla tua lista di osservazione',
    claimedProfits: 'Fantastico! Hai richiesto i tuoi profitti',
    filePriceUpdated: 'Fantastico! Il prezzo del tuo file è stato aggiornato',
    approvedSelectedFiles: 'Fantastico! Hai approvato i file selezionati',
    blockedSelectedFiles: 'Fantastico! Hai bloccato i file selezionati',
    promotionPriceAdded: 'Fantastico! Il prezzo promozionale è stato impostato',
    ownerShipChanged: 'Fantastico! La proprietà è stata trasferita a un altro account',
    fileIsPromoted: 'Fantastico! Il tuo file è stato promosso',
    fileUploaded: 'Fantastico! Hai caricato con successo un file',
    filesTooLarge: 'I file sono troppo grandi',
    buy: 'Acquista',
    charts: 'Grafici',
    audit: 'Audit',
    lottery: 'Lotteria',
    frequAskedQuestions: 'Domande frequenti',
    whoAreWe: 'Chi siamo?',
    whoWeAreAnsw:
        'Siamo un team di sviluppatori Web3, che stanno implementando ciò che sarà il futuro di Internet, blockchain e decentralizzazione. Questo progetto consente a ciascun utente di vendere in modo sicuro i propri file, direttamente dal proprio PC o smartphone, e senza intermediari.',
    maxTotalSupply: 'Fornitura massima/total',
    assetPipeLine: 'e pipeline degli asset per le blockchain.',
    tax: 'Tassa',
    sell: 'Vendi',
    holderRewards: 'Ricompense per i titolari',
    marketing: 'Marketing',
    whitePaper: 'Whitepaper',
    trade: 'Scambio',
    official: 'Ufficiale',
    clickHere: 'Clicca qui',
    difiDetailsQuest: 'Dettagli e collegamenti di DIFI',
    functionsOfDifiMarket: 'Funzioni del mercato DIFI?',
    onDifiMarketYouCan: 'Nel mercato DIFI puoi',
    difiMarketBullet1: 'Vendere i tuoi file, programmi e qualsiasi tipo di file digitale.',
    difiMarketBullet2: 'Acquistare file da altri utenti.',
    difiMarketBullet3: 'Lasciare un feedback dopo un acquisto.',
    difiMarketBullet4: 'Prelievo dei tuoi guadagni in DIFI.',
    whatWalletSupportedQues: 'Quali portafogli sono supportati?',
    whatWalletSupportedAnsw:
        'Digital Files utilizza WalletConnect e supporta dozzine di portafogli diversi per interagire con il negozio.',
    whatAreCommissionQuest: 'Quali sono le commissioni?',
    fees: 'Commissioni',
    onEachSale: 'su ogni vendita',
    whatAreCommissionAnsw:
        'Viene applicata una tassa del 1% su ogni file venduto, il sistema utilizza la blockchain e IPFS e la tassa del 1% su ogni vendita ci permette di mantenere il sistema perfettamente funzionante.',
    uploadLimit: 'Limiti di caricamento?',
    uploadLimitAnsw1: 'Il limite massimo di caricamento per ogni file è di circa 100 MB.',
    uploadLimitAnsw2: 'Non ci sono limiti al numero di file che un utente può caricare e vendere.',
    whatDoIBuyWith: 'Con cosa posso acquistare i file?',
    whatDoIBuyWithAnsw:
        'I file possono essere acquistati utilizzando il token DIFI, anche alcuni BNB sono necessari per gestire le transazioni in quanto è la valuta nativa.',
    canIChangePrice: 'Posso cambiare il prezzo di un file?',
    canIChangePriceAnsw:
        'Al momento non è possibile cambiare il prezzo di un file, fai i calcoli correttamente in base al prezzo del DIFI quando vendi un file.',
    moreQuestions: 'Altre domande?',
    ifYouCantFindAnsw: 'Se non riesci a trovare le risposte qui',
    getInTouch: 'mettiti in contatto',
    weWillHelp: 'Saremo lieti di aiutarti',
    education: 'Materiali di formazione',
    eBooks: 'eBook',
    gDesign: 'Grafica',
    os: 'Sistemi operativi',
    programming: 'Programmazione',
    antiVirus: 'Antivirus e sicurezza',
    db: 'Database',
    mutimedia: 'Multimedia',
    gaming: 'Gaming',
    openSource: 'Open Source',
    adult: 'Adulti 18+',

    // Latest Translations keys --------------
    faqs: 'Domande frequenti',
    contactUs: 'Contattaci',
    aboutUs: 'Chi siamo',
    activity: 'Attività',
    aboutUsHeading: 'Chi siamo',
    wordsAboutUsHeading: 'Alcune parole su',
    appLauncingTime: 'DIFI lanciato il 1 aprile 2023',
    aboutWord1: 'Il primo mercato di Files Digitali creato sulla Blockchain nel web3, Powered by Binance Smart Chain.',
    aboutWord2:
        'Compra, Vendi e Promuovi le tue Creazioni Digitali nel modo più semplice e sicuro sul mercato. Nessuna registrazione, nessuna terza parte coinvolta. Questa è la Decentralizzazione DIFI.',
    creator: 'Creatore',
    topFeatureHeading: 'Caratteristiche principali',
    toPFeatureSubheading: 'Le 3 principali caratteristiche del mercato DIFI che sono senza rivali!',
    decentralized: 'Decentralizzato',
    decentralizedDescription:
        'Il mercato DIFI è decentralizzato al 100%, tutto è salvato sulla Binance Smart Chain per la massima sicurezza. Gli utenti interagiscono collegando il loro portafoglio senza gestione esterna.',
    hackerFreeHeading: 'Senza hacker',
    hackerFreeDescription:
        'Il sistema non salva nulla sul server web, non ci sono dati a rischio. Gli utenti interagiscono con il loro portafoglio quando sono connessi, nulla può essere manipolato e gli amministratori non hanno accesso ai fondi degli utenti.',
    lowFeesHeading: 'Commissioni basse',
    lowFeesDescription:
        "Probabilmente tra le più basse a livello globale, solo una commissione del 1% sulle vendite, nessuna altra commissione nascosta, gli utenti possono prelevare DIFI dal loro account quando vogliono, senza attese o conferme, gli utenti pagano in DIFI solo al momento dell'ordine.",
    aboutUsQuote: '"Con il DIFI Market siamo già nel futuro, prima di tutto."',
    token: 'Token',
    founder: 'Fondatore',
    digitalFilesMarket: 'Mercato di file digitali',
    subscribeThanks: "Grazie per l'iscrizione",
    toastError: 'Ops! Qualcosa è andato storto!',
    resources: 'Risorse',
    newsletterSignup: 'Iscriviti alla newsletter',
    newsletterText: 'Iscriviti alla newsletter e resta aggiornato',
    newsletterErrMsg: 'Inserisci il tuo indirizzo email',
    newsletterPlaceholder: 'Aggiungi il tuo indirizzo email',
    sitemap: 'Mappa del sito',
    bestSellers: 'Migliori venditori',
    topRated: 'Più votati',
    home: 'Home',
    files: 'File',
    resetFilters: 'Resetta filtri',
    searchForFiles: 'Cerca prodotti',
    noSearchResults: 'Nessun prodotto corrisponde alla tua ricerca',
    noFilesAtTheMoment: 'Al momento non ci sono file.',
    emptyHere: 'È vuoto qui',
    poromotionEndsAt: 'La promozione termina il',
    sortByRate: 'Ordina per valutazione',
    shopBrandDescription: 'Negozio di Files Digitali Decentralizzato Web3',
    uploadFileNav: 'Carica file',
    account: 'Account',
    wishlist: 'Lista dei desideri',
    upload: 'Carica',
    menulegal: 'Legale',
    menuprivacy: 'Politica sulla Privacy',
    menutos: 'Termini di Servizio',
    menulegaopinion: 'Parere Legale',
    share: 'Condividi',
};
