/* ------------------------------------------------------------------------------------------ 
      ENGLISH TRANSLATIONS
------------------------------------------------------------------------------------------ */

export const araboTranslation = {
    recentlyAdded: 'تمت إضافته مؤخرًا',
    topTrending: 'الأكثر انتشارًا',
    featuredItems: 'ملفاتنا المميزة',
    noCategoryMatch: 'لا توجد ملفات تطابق هذا التصنيف',
    noReviewsYet: 'لا توجد تقييمات بعد',
    accessNow: 'الوصول الآن',
    downloadNow: 'تحميل الآن',
    accessFileFor: 'الوصول إلى هذا الملف مقابل',
    youHaveAccess: 'لديك الوصول بالفعل إلى هذا الملف',
    viewScreenshots: 'عرض لقطات الشاشة',
    promoted: 'مُرقَّى',
    addToList: 'إضافة إلى قائمتي',
    inList: 'في قائمتك',
    fileReviews: 'تقييمات الملف',
    fileGallery: 'معرض الصور',
    pending: 'قيد الانتظار',
    pendingMsg: 'هذا الملف في انتظار موافقة المسؤول',
    from: 'من',
    ratings: 'التقييمات',
    promoteThisFile: 'ترقية هذا الملف',
    promoteFor: 'ترقية هذا بمبلغ',
    youHaveReview: 'لقد قمت بالفعل بتقييم هذا الملف',
    rateThisFile: 'قيم هذا الملف',
    fileHasNoReviews: 'هذا الملف ليس لديه تقييمات بعد',
    discoverAll: 'اكتشف جميع الملفات',
    filterByCategory: 'تصفية حسب التصنيف',
    sortByDate: 'ترتيب حسب التاريخ',
    sortByPrice: 'ترتيب حسب السعر',
    loadMore: 'تحميل المزيد',
    select: 'اختر',
    all: 'الكل',
    noMatches: 'لا توجد ملفات تطابق هذا الفلتر',
    newest: 'الأحدث أولاً',
    oldest: 'الأقدم أولاً',
    highPrice: 'السعر: من الأعلى إلى الأدنى',
    lowPrice: 'السعر: من الأدنى إلى الأعلى',
    headerLinks: 'روابط',
    headerDiscover: 'اكتشف',
    headerActivity: 'النشاط',
    headerFaqs: 'الأسئلة الشائعة',
    headerContactUs: 'اتصل بنا',
    trackTransaction: 'تتبع المعاملات',
    headerMyAccount: 'حسابي',
    headerMyWishList: 'قائمة رغباتي',
    headerAdminPanel: 'لوحة المشرف',
    headerAdminOnly: 'للمشرفين فقط',
    weAreSocial: 'نحن اجتماعيون',
    createdBy: 'تم الإنشاء بواسطة',
    copyRights: 'جميع الحقوق محفوظة',
    activityHeading: 'الأنشطة',
    tableActivityHeading: 'أحدث الأنشطة',
    tableNoRecords: 'لا توجد أنشطة حتى الآن لعرضها',
    tableUser: 'المستخدم',
    tableTime: 'الوقت',
    tableAction: 'الإجراء',
    tableMintFile: 'ملف مرفوع',
    tableApproveMint: 'ملف موافق عليه',
    tableBuyFile: 'ملف مشتراة',
    tableWishList: 'إضافة ملف إلى قائمة المراقبة',
    tableNewSubscriber: 'مشترك جديد',
    tablePromote: 'تمت إضافة الملف للترقية',
    contactPageHeading: 'تواصل معنا بشأن أي مشكلة',
    contactPageSubHeading: 'املأ النموذج بما تحتاجه وسنتواصل معك عبر البريد الإلكتروني في أقل من 24 ساعة',
    followUs: 'تابعنا لتظل على اطلاع.',
    yourNameLabel: 'اسمك',
    yourNamePlaceholder: 'أدخل اسمك',
    yourNameErrorMsg: 'يرجى إدخال اسمك',
    yourEmail: 'بريدك الإلكتروني',
    yourEmailPlaceholder: 'أدخل عنوان بريدك الإلكتروني',
    yourEmailErrorMsg: 'يرجى إدخال عنوان بريد إلكتروني صحيح',
    yourEmailInvalidPattern: 'عنوان بريد إلكتروني غير صحيح',
    yourMessage: 'رسالتك',
    yourMessagePlaceholder: 'رسالتك بخصوص أي طلب',
    yourMessageErrorMsg: 'يرجى إدخال رسالتك',
    submitRequest: 'إرسال طلبك',
    submitting: 'جارٍ التقديم...',
    successSubmitHeading: 'شكراً',
    successSubmitMsg: 'سنعود إليك في أقرب وقت ممكن',
    returnHome: 'العودة إلى الصفحة الرئيسية',
    utility: 'أداة',
    title: 'العنوان',
    titleErrorMsg: 'يرجى إدخال عنوان الفيديو الخاص بك',
    titlePlaceHolder: 'أدخل عنوان المنتج الخاص بك',
    description: 'الوصف',
    descriptionErrorMsg: 'يرجى إدخال وصف الملف الخاص بك',
    descriptionPlaceholder: 'أضف وصفًا قصيرًا عن ملفك',
    category: 'الفئة',
    categoryPlaceholder: 'اختر فئة',
    compatibleWith: 'متوافق مع',
    compatibleWithPlaceholder: 'حدد توافق نظام التشغيل',
    compatibleWithErrorMsg: 'يرجى اختيار أي نظام تشغيل يتوافق مع هذا البرنامج',
    price: 'السعر',
    pricePlaceholder: 'أدخل السعر المطلوب للملف الخاص بك',
    priceErrorMsg: 'يرجى إدخال سعر الملف الخاص بك',
    uploadFile: 'قم بتحميل ملفك',
    uploadFileErrorMsg: 'يرجى تحميل الملف الخاص بك',
    uploadFileThumb: 'تحميل الصورة المصغرة للملف',
    uploadFileThumbErrorMsg: 'يرجى تحميل صورة مصغرة للملف',
    uploadFileScreenshots: 'تحميل لقطات الشاشة للملف',
    uploadFileScreenshotsErrorMsg: 'يرجى تحميل لقطات الشاشة للملف',
    wishlistPageHeading: 'قائمة الرغبات',
    myAccountHeading: 'حسابي',
    sellNewProduct: 'بيع منتج جديد',
    salesProfits: 'أرباح المبيعات',
    noProfitsYet: 'ليس لديك أي أرباح حتى الآن',
    claimProfits: 'اطلب الأرباح',
    accTableFile: 'الملف',
    accTableCreator: 'المُنشئ',
    accTableCreatedAt: 'تم الإنشاء في',
    filesIHaveUploaded: 'الملفات التي قمت بتحميلها',
    accNoUploadedFiles: 'لا توجد ملفات محملة لعرضها',
    accPendingFiles: 'ملفاتي قيد الانتظار',
    accNoPendingFiles: 'لا توجد ملفات قيد الانتظار لعرضها',
    accPurchasedFiles: 'الملفات التي قمت بشرائها',
    accNoPurchasedFiles: 'لا توجد ملفات مشتراة لعرضها',
    adminPanel: 'لوحة المشرف',
    appProfits: 'أرباح التطبيق',
    approveSelected: 'الموافقة على المحدد',
    blockSelected: 'حظر المحدد',
    noFilesToDisplay: 'لا توجد ملفات لعرضها',
    allPendingFiles: 'جميع الملفات المعلقة',
    status: 'الحالة',
    active: 'نشط',
    setPromotionPrice: 'تعيين سعر الترويج',
    currentPriceIs: 'السعر الحالي هو',
    promotionPrice: 'سعر الترويج',
    promotionPricePlaceholder: 'أدخل سعر الترويج',
    promotionPriceErrorMsg: 'يرجى إضافة سعر الترويج',
    setPrice: 'تعيين السعر',
    transferContractOwnership: 'نقل ملكية العقود',
    newOwner: 'المالك الجديد',
    newOwnerPlaceholder: 'أدخل عنوان المالك الجديد',
    newOwnerErrorMsg: 'عنوان غير صالح! يرجى التحقق من العنوان الذي أدخلته',
    transfer: 'نقل',
    newOwnerSuccessHeading: 'لم تعد مالكًا لهذا العقد',
    newOwnerSuccessText: 'تم نقل الملكية إلى حساب آخر الآن، لم تعد لديك وصول إلى هذه الصفحة. حظًا سعيدًا',
    updatePrice: 'تحديث السعر',
    newPrice: 'سعر جديد',
    updateProductPrice: 'تحديث سعر المنتج',
    equals: 'يساوي',
    txLoadingHeading: 'عادةً ما يستغرق ذلك بعض الوقت',
    txLoadingMsg: 'نحن نقوم بمعالجة عمليتك، يرجى عدم إعادة تحميل المتصفح الخاص بك',
    txLoadingSmText: 'معالجة العملية',
    rating: 'التقييم',
    reason: 'السبب',
    enterRatingReason: 'يرجى إدخال سبب التقييم',
    reasonPleaceholder: 'مثال: جودة الشفرة',
    message: 'الرسالة',
    messagePlaceholder: 'أدخل رسالة التقييم الخاصة بك',
    ratingMsgError: 'يرجى إدخال رسالة التقييم',
    submitRating: 'إرسال تقييمك',
    close: 'إغلاق',
    uploadingYourAsset: 'جارٍ تحميل أصولك',
    ipfsMsg: 'قد يستغرق هذا بعض الوقت حتى نحفظ أصولك في IFPS...',
    cover: 'الغلاف',
    avatar: 'الصورة الرمزية',
    screenshot: 'لقطة الشاشة',
    appRunOn: 'تم تعيين تشغيل هذا التطبيق على',
    connectToNetwork: 'يرجى الاتصال بشبكة الإنترنت',
    switchNetwork: 'تغيير الشبكة',
    oneOfYourItem: 'أحد ملفاتك',
    err: 'عفوًا! حدث خطأ ما',
    youHaveAccessNow: 'عظيم! لديك الآن الوصول',
    fillTheFields: 'يرجى ملء الحقول لتتمكن من التقديم',
    addedToWatchlist: 'عظيم! لقد أضفت هذا الملف إلى قائمة المراقبة الخاصة بك',
    claimedProfits: 'عظيم! لقد ادعيت أرباحك',
    filePriceUpdated: 'عظيم! تم تحديث سعر الملف الخاص بك',
    approvedSelectedFiles: 'عظيم! لقد وافقت على الملفات المحددة',
    blockedSelectedFiles: 'عظيم! لقد قمت بحظر الملفات المحددة',
    promotionPriceAdded: 'عظيم! تم تعيين سعر الترويج',
    ownerShipChanged: 'عظيم! تم نقل الملكية إلى حساب آخر',
    fileIsPromoted: 'عظيم! تم ترقية ملفك',
    fileUploaded: 'عظيم! لقد قمت بتحميل الملف بنجاح',
    filesTooLarge: 'الملفات كبيرة جدًا',
    buy: 'شراء',
    charts: 'الرسوم البيانية',
    audit: 'تدقيق',
    lottery: 'اليانصيب',
    frequAskedQuestions: 'الأسئلة المتكررة',
    whoAreWe: 'من نحن؟',
    whoWeAreAnsw:
        'نحن فريق من المطورين الذين يعملون في مجال الويب3 ، والذين يقومون بتنفيذ ما سيكون مستقبل الإنترنت والبلوكتشين واللامركزية. يتيح لك هذا المشروع بيع ملفاتك بأمان مباشرة من جهاز الكمبيوتر الخاص بك أو الهاتف الذكي ، وذلك بدون وسطاء طرف ثالث.',
    maxTotalSupply: 'الحد الأقصى / الإمداد الكلي',
    assetPipeLine: 'وخط الأصول للبلوكشين.',
    tax: 'الضريبة',
    sell: 'بيع',
    holderRewards: 'مكافآت المالكين',
    marketing: 'التسويق',
    whitePaper: 'الورقة البيضاء',
    trade: 'تداول',
    official: 'رسمي',
    clickHere: 'انقر هنا',
    difiDetailsQuest: 'تفاصيل وروابط DIFI',
    functionsOfDifiMarket: 'وظائف سوق DIFI؟',
    onDifiMarketYouCan: 'على سوق DIFI يمكنك',
    difiMarketBullet1: 'بيع الملفات الخاصة بك والبرامج وأي نوع من الملفات الرقمية.',
    difiMarketBullet2: 'شراء ملفات المستخدمين الآخرين.',
    difiMarketBullet3: 'ترك تقييم بعد الشراء.',
    difiMarketBullet4: 'سحب أرباحك من المبيعات.',
    whatWalletSupportedQues: 'ما هي المحافظ المدعومة؟',
    whatWalletSupportedAnsw: 'تستخدم Digital Files WalletConnect وتدعم العديد من المحافظ المختلفة للتفاعل مع المتجر.',
    whatAreCommissionQuest: 'ما هي العمولات؟',
    fees: 'الرسوم',
    onEachSale: 'على كل بيع',
    whatAreCommissionAnsw:
        'يتم تطبيق ضريبة بنسبة 1% على كل ملف يتم بيعه، يستخدم النظام البلوكشين وIPFS، وتسمح الضريبة بنسبة 1% على كل بيع بأن نحافظ على سلامة وسير تشغيل النظام بشكل مثالي.',
    uploadLimit: 'حدود الرفع؟',
    uploadLimitAnsw1: 'الحد الأقصى لرفع كل ملف هو حوالي 100 ميجابايت',
    uploadLimitAnsw2: 'لا توجد حدود لعدد الملفات التي يمكن للمستخدم رفعها وبيعها.',
    whatDoIBuyWith: 'بماذا أشتري الملفات؟',
    whatDoIBuyWithAnsw:
        'يمكن شراء الملفات باستخدام رمز DIFI، وحتى بضعة BNB تحتاج إلى إدارة المعاملات حيث إنها العملة الأصلية.',
    canIChangePrice: 'هل يمكنني تغيير سعر الملف؟',
    canIChangePriceAnsw:
        'في الوقت الحالي ليس من الممكن تغيير سعر الملف، قم بحسابات صحيحة بناءً على سعر DIFI عند بيع الملف.',
    moreQuestions: 'أسئلة أكثر؟',
    ifYouCantFindAnsw: 'إذا لم تتمكن من إيجاد الإجابات هنا',
    getInTouch: 'تواصل معنا',
    weWillHelp: 'سنكون سعداء للمساعدة',
    education: 'مواد تعليمية',
    eBooks: 'كتب إلكترونية',
    gDesign: 'تصميم جرافيك',
    os: 'أنظمة التشغيل',
    programming: 'البرمجة',
    antiVirus: 'برامج مكافحة الفيروسات والأمان',
    db: 'قواعد البيانات',
    mutimedia: 'وسائط متعددة',
    gaming: 'ألعاب',
    openSource: 'مفتوح المصدر',
    adult: 'للبالغين فقط 18+',

    // Latest Translations keys --------------
    faqs: 'الأسئلة الشائعة',
    contactUs: 'اتصل بنا',
    aboutUs: 'من نحن',
    activity: 'الأنشطة',
    aboutUsHeading: 'من نحن',
    wordsAboutUsHeading: 'بعض الكلمات عنا',
    appLauncingTime: 'تم إطلاق DIFI في 1 أبريل 2023',
    aboutWord1: 'أول سوق للملفات الرقمية التي تم إنشاؤها على البلوكشين في الويب3، مدعوم بواسطة سلسلة بينانس الذكية.',
    aboutWord2:
        'قم بشراء وبيع وتعزيز إبداعاتك الرقمية بأسهل وأسلم طريقة في السوق. لا تسجيل، لا جهات خارجية مشاركة. هذه هي فكرة اللامركزية DIFI.',
    creator: 'المبتكر',
    topFeatureHeading: 'أهم المزايا',
    toPFeatureSubheading: 'أعلى 3 مزايا في سوق DIFI التي لا مثيل لها!',
    decentralized: 'غير مركزي',
    decentralizedDescription:
        'سوق DIFI غير مركزي بنسبة 100٪، يتم حفظ كل شيء على Binance Smart Chain لضمان أقصى قدر من الأمان، يتفاعل المستخدمون من خلال ربط محفظتهم دون إدارة خارجية.',
    hackerFreeHeading: 'خالٍ من الاختراق',
    hackerFreeDescription:
        'لا يحفظ النظام أي شيء على خادم الويب، ولا يوجد بيانات معرضة للخطر، يتفاعل المستخدمون مع محفظتهم عند الاتصال، لا يمكن التلاعب بأي شيء، ولا يمتلك المسؤولون أي وصول إلى أموال المستخدمين.',
    lowFeesHeading: 'رسوم منخفضة',
    lowFeesDescription:
        'ربما تكون منخفضة عالميًا بنسبة 1٪ فقط على المبيعات، لا توجد رسوم خفية أخرى، يمكن للمستخدمين سحب DIFI من حسابهم في أي وقت يرغبون فيه، دون الانتظار أو التأكيدات، يدفع المستخدمون بـ DIFI فقط في وقت الطلب.',
    aboutUsQuote: '"مع سوق DIFI، نحن بالفعل في المستقبل، قبل كل شيء."',
    token: 'رمز',
    founder: 'المؤسس',
    digitalFilesMarket: 'سوق الملفات الرقمية',
    subscribeThanks: 'شكرًا لاشتراكك',
    toastError: 'عفوًا! حدث خطأ ما!',
    resources: 'الموارد',
    newsletterSignup: 'اشتراك في النشرة الإخبارية',
    newsletterText: 'اشترك في النشرة الإخبارية وابقَ محدثًا',
    newsletterErrMsg: 'يرجى إدخال بريدك الإلكتروني',
    newsletterPlaceholder: 'أضف بريدك الإلكتروني',
    sitemap: 'خريطة الموقع',
    bestSellers: 'أفضل البائعين',
    topRated: 'أعلى التقييمات',
    home: 'الصفحة الرئيسية',
    files: 'الملفات',
    resetFilters: 'إعادة تعيين الفلاتر',
    searchForFiles: 'ابحث عن المنتجات',
    noSearchResults: 'لا توجد منتجات تطابق بحثك',
    noFilesAtTheMoment: 'لا توجد ملفات في الوقت الحالي.',
    emptyHere: 'هنا فارغ',
    poromotionEndsAt: 'تنتهي الترقية في',
    sortByRate: 'ترتيب حسب التقييم',
    shopBrandDescription: 'متجر الملفات الرقمية المفتوح على الويب3',
    uploadFileNav: 'تحميل الملف',
    account: 'الحساب',
    wishlist: 'القائمة المفضلة',
    upload: 'تحميل',
    menulegal: 'قانوني',
    menuprivacy: 'سياسة الخصوصية',
    menutos: 'شروط الخدمة',
    menulegaopinion: 'رأي قانوني',
    share: 'مشاركة',
};
