import React from 'react';

// COMPOENENTS
import PageBanner from '../../components/general/PageBanner';
import useApp from '../../hooks/useApp';
import { useTranslation } from 'react-i18next';

function AboutUsPage() {
    const { themeMode } = useApp();
    const { t } = useTranslation();

    return (
        <>
            <PageBanner heading={t('aboutUsHeading')}></PageBanner>

            <section className='py-5'>
                <div className='container py-5'>
                    <div className='row'>
                        <div className='col-lg-10 mx-auto'>
                            <div className='row gy-4 gx-5 justify-content-between'>
                                <div className='col-lg-4'>
                                    <h2>{t('wordsAboutUsHeading')}</h2>
                                    <p className='text-muted'>{t('appLauncingTime')}</p>
                                </div>
                                <div className='col-lg-4'>
                                    <p>{t('aboutWord1')}</p>
                                </div>
                                <div className='col-lg-4'>
                                    <p>{t('aboutWord2')}</p>
                                    <h5 className='text-primary mb-0'>The Dev</h5>
                                    <p className='text-muted'>DIFI {t('creator')}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className={`py-5 ${themeMode === 'light' ? 'bg-light' : 'bg-dark'}`}>
                <div className='container py-5'>
                    <header className='mb-5 text-center'>
                        <h2 className='h1'>{t('topFeatureHeading')}</h2>
                        <p className='text-muted'>{t('toPFeatureSubheading')}</p>
                    </header>
                    <div className='row'>
                        <div className='col-lg-10 mx-auto'>
                            <div className='row g-4 text-center'>
                                <div className='col-lg-4'>
                                    <div className='card mb-0 p-lg-4 shadow-0'>
                                        <div className='card-body'>
                                            <h4>{t('Decentralized')}</h4>
                                            <p className='text-muted mb-0'>{t('decentralizedDescription')}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-4'>
                                    <div className='card mb-0 p-lg-4 shadow-0'>
                                        <div className='card-body'>
                                            <h4>{t('hackerFreeHeading')}</h4>
                                            <p className='text-muted mb-0'>{t('hackerFreeDescription')}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-4'>
                                    <div className='card mb-0 p-lg-4 shadow-0'>
                                        <div className='card-body'>
                                            <h4>{t('lowFeesHeading')}</h4>
                                            <p className='text-muted mb-0'>{t('lowFeesDescription')}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='py-5'>
                <div className='container text-center py-5'>
                    <div className='row'>
                        <div className='col-lg-6 mx-auto'>
                            <p className='h3 fw-light'>{t('aboutUsQuote')}</p>
                            <h5 className='text-primary'>The Dev</h5>
                            <p className='text-muted'>
                                DIFI {t('token')}, {t('founder')}
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className='pb-5'>
                <div className='container text-center'>
                    <div className='row g-3 align-items-center'>
                        <div className='col-lg-4'>
                            <img
                                src='/logo-1.png'
                                alt='Binance Smart Chain'
                                width='80'
                                className='img-fluid logo-img'
                            />
                        </div>
                        <div className='col-lg-4'>
                            <img src='/logo-2.png' alt='Wallet Connect' width='80' className='img-fluid logo-img' />
                        </div>
                        <div className='col-lg-4'>
                            <img src='/logo-3.png' alt='metamask' width='80' className='img-fluid logo-img' />
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default AboutUsPage;
