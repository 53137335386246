import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { AvgRatingWithTotal } from './RatingDisplay';
import { RiMoneyDollarCircleFill } from 'react-icons/ri';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, EffectFade } from 'swiper';
import { FaShippingFast } from 'react-icons/fa';
import { BiLinkAlt } from 'react-icons/bi';
import { BsImages } from 'react-icons/bs';
import { AiOutlineExpand } from 'react-icons/ai';
import useWeb3 from '../../hooks/useWeb3';
import useApp from '../../hooks/useApp';
import { truncateStart } from '../../helpers/utils';
import Viewer from 'react-viewer';
import { Jazzicon } from '@ukstv/jazzicon-react';

function ProductCard({ id, metadata, price, reviews, tokenPrice, usdPrice, creator, buyers }) {
    const { account } = useWeb3();
    const { setFileView, setFileViewSrc } = useApp();
    const images = [metadata?.thumbnail, ...metadata?.screenshots]?.map((img) => ({ src: img }));
    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <div className='card mb-0 rounded position-relative shop-card product-card shadow-0'>
                <Link className='ribbon-2 text-reset' to={`/files/category/${metadata?.category}`}>
                    {metadata?.category}
                </Link>
                <div className='card-body p-0'>
                    <div
                        className='swiper-sm-nav position-relative'
                        style={{ background: `url(${metadata.thumbnail})` }}
                    >
                        <Swiper spaceBetween={0} slidesPerView={1} modules={[Navigation, EffectFade]} navigation>
                            {[metadata?.thumbnail, ...metadata?.screenshots]?.map((img, index) => {
                                return (
                                    <SwiperSlide key={index}>
                                        <div
                                            className='product-gallery-slide no-p rounded-0 cursor-pointer'
                                            key={index}
                                            style={{ background: `url(${img})` }}
                                        ></div>
                                    </SwiperSlide>
                                );
                            })}
                        </Swiper>

                        <div className='product-cta'>
                            {account === creator ? (
                                <>
                                    <Link to={`/files/${id}`} className='cta-btn'>
                                        <BiLinkAlt size='1.3rem' />
                                    </Link>
                                </>
                            ) : (
                                <>
                                    <Link to={`/files/${id}`} className='cta-btn'>
                                        <FaShippingFast size='1.3rem' />
                                    </Link>
                                </>
                            )}
                            <button
                                className='cta-btn'
                                onClick={() => {
                                    setFileView(true);
                                    setFileViewSrc({
                                        name: metadata?.title,
                                        creator,
                                        id,
                                        description: metadata?.description,
                                        gallery: metadata?.screenshots,
                                        category: metadata?.category,
                                        price,
                                        tokenPrice,
                                        reviews,
                                        buyers,
                                    });
                                }}
                            >
                                <AiOutlineExpand size='1.3rem' />
                            </button>

                            <button
                                type='btn'
                                className='cta-btn'
                                onClick={() => {
                                    setIsOpen(true);
                                }}
                            >
                                <BsImages size='1.3rem' />
                            </button>
                        </div>
                    </div>
                    <div className='p-3 px-4'>
                        <h2 className='h6 font-family-base mt-1' style={{ minHeight: '39px' }}>
                            <Link to={`/files/${id}`} className='text-reset'>
                                {truncateStart(metadata?.title, 35)}
                            </Link>
                        </h2>
                        <div className='my-2'>
                            <div className='text-xxs badge fw-normal bg-secondary'>
                                <RiMoneyDollarCircleFill
                                    size='1rem'
                                    className='me-1'
                                    style={{ transform: 'translateY(-2px)' }}
                                />{' '}
                                {usdPrice} USD - <img src='/token.png' alt='DIFI' width='20' /> {tokenPrice} DIFI
                            </div>
                        </div>
                        <div className='mt-2 d-flex'>
                            <AvgRatingWithTotal reviews={reviews} />
                        </div>

                        {buyers?.length > 0 && (
                            <ul className='list-unstyled avatars avatars-sm avatars-with-transition mb-0'>
                                {buyers.slice(0, 4).map((buyer, i) => {
                                    return (
                                        <li className='avatar avatar-md2' key={i}>
                                            <div style={{ width: '25px', height: '25px' }}>
                                                <Jazzicon address={buyer} />
                                            </div>
                                        </li>
                                    );
                                })}
                            </ul>
                        )}
                    </div>
                </div>
            </div>

            {isOpen && (
                <Viewer
                    visible={isOpen}
                    onClose={() => {
                        setIsOpen(false);
                    }}
                    images={images}
                />
            )}
        </>
    );
}

export default ProductCard;
