import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import Header from '../components/general/Header';
import Footer from '../components/general/Footer';
import useFiles from '../hooks/useFiles';
import useWeb3 from '../hooks/useWeb3';
import useStream from '../hooks/useStream';
import ScrollToTop from '../components/general/ScrollToTop';
import { appSettings } from '../helpers/settings';
import { uniqueNamesGenerator, starWars } from 'unique-names-generator';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-range-slider-input/dist/style.css';
import { useTranslation } from 'react-i18next';

import FilesAbi from '../contracts/Collectible.json';
import StreamAbi from '../contracts/StreamList.json';
import TokenAbi from '../integration/TokenAbi.json';

import AOS from 'aos';

import MetaMaskLoader from '../components/general/MetaMaskLoader';
import PreviewModal from '../components/general/PreviewModal';
import ViewOnlyAlert from '../components/general/ViewOnlyAlert';
import Modal from '../components/general/Modal';

import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum';
import { Web3Modal } from '@web3modal/react';
import { configureChains, createClient, WagmiConfig, useNetwork } from 'wagmi';
import { bsc } from 'wagmi/chains';
import { getAccount, watchAccount, getContract } from '@wagmi/core';
import useApp from '../hooks/useApp';
import Popup from '../components/general/Popup';
import FilePreview from '../components/general/FilePreview';
import MobileNav from '../components/general/MobileNav';
import { AdMob, BannerAdOptions, BannerAdSize, BannerAdPosition, BannerAdPluginEvents, AdMobBannerSize, AdOptions, AdLoadInfo, InterstitialAdPluginEvents, RewardAdOptions, RewardAdPluginEvents, AdMobRewardItem } from '@capacitor-community/admob';
import { PushNotifications } from '@capacitor/push-notifications';

const addListeners = async () => {
  await PushNotifications.addListener('registration', token => {
    console.info('Registration token: ', token.value);
  });

  await PushNotifications.addListener('registrationError', err => {
    console.error('Registration error: ', err.error);
  });

  await PushNotifications.addListener('pushNotificationReceived', notification => {
    console.log('Push notification received: ', notification);
  });

  await PushNotifications.addListener('pushNotificationActionPerformed', notification => {
    console.log('Push notification action performed', notification.actionId, notification.inputValue);
  });
}

const registerNotifications = async () => {
  let permStatus = await PushNotifications.checkPermissions();

  if (permStatus.receive === 'prompt') {
    permStatus = await PushNotifications.requestPermissions();
  }

  if (permStatus.receive !== 'granted') {
    throw new Error('User denied permissions!');
  }

  await PushNotifications.register();
}

const getDeliveredNotifications = async () => {
  const notificationList = await PushNotifications.getDeliveredNotifications();
  console.log('delivered notifications', notificationList);
}
export async function initialize(): Promise<void> {
  const { status } = await AdMob.trackingAuthorizationStatus();

  if (status === 'notDetermined') {
    /**
     * If you want to explain TrackingAuthorization before showing the iOS dialog,
     * you can show the modal here.
     * ex)
     * const modal = await this.modalCtrl.create({
     *   component: RequestTrackingPage,
     * });
     * await modal.present();
     * await modal.onDidDismiss();  // Wait for close modal
     **/
  }
 
  AdMob.initialize({
    requestTrackingAuthorization: false,
    testingDevices: ['2077ef9a63d2b398840261c8221a0c9b'],
    initializeForTesting: false,
  });
}

export async function banner(): Promise<void> {
    AdMob.addListener(BannerAdPluginEvents.Loaded, () => {
      // Subscribe Banner Event Listener
    });

    AdMob.addListener(BannerAdPluginEvents.SizeChanged, (size: AdMobBannerSize) => {
      // Subscribe Change Banner Size
    });

    const options: BannerAdOptions = {
      adId: 'ca-app-pub-2678760727259403/1211760574',
      adSize: BannerAdSize.BANNER,
      position: BannerAdPosition.TOP_CENTER,
      margin: 0,
      // isTesting: true
      // npa: true
    };
    AdMob.showBanner(options);
}
export async function interstitial(): Promise<void> {
  AdMob.addListener(InterstitialAdPluginEvents.Loaded, (info: AdLoadInfo) => {
    // Subscribe prepared interstitial
  });

  const options: AdOptions = {
    adId: 'ca-app-pub-2678760727259403/4959433897',
    // isTesting: true
    // npa: true
  };
  await AdMob.prepareInterstitial(options);
  await AdMob.showInterstitial();
}
export async function rewardVideo(): Promise<void> {
  AdMob.addListener(RewardAdPluginEvents.Loaded, (info: AdLoadInfo) => {
    // Subscribe prepared rewardVideo
  });

  AdMob.addListener(RewardAdPluginEvents.Rewarded, (rewardItem: AdMobRewardItem) => {
    // Subscribe user rewarded
    console.log(rewardItem);
  });

  const options: RewardAdOptions = {
    adId: 'ca-app-pub-2678760727259403/9828617194',
    // isTesting: true
    // npa: true
    // ssv: {
    //   userId: "A user ID to send to your SSV"
    //   customData: JSON.stringify({ ...MyCustomData })
    //}
  };
  await AdMob.prepareRewardVideoAd(options);
  const rewardItem = await AdMob.showRewardVideoAd();
}
setTimeout(() => {
interstitial();
}, 60000);
setTimeout(() => {
rewardVideo();
}, 90000);
const chains = [bsc];
const projectId = appSettings.wcProjectId;

const { provider, webSocketProvider } = configureChains(chains, [w3mProvider({ projectId })]);
const wagmiClient = createClient({
    autoConnect: true,
    connectors: w3mConnectors({ projectId, version: 2, chains }),
    provider,
    webSocketProvider,
});
const ethereumClient = new EthereumClient(wagmiClient, chains);

function Layout() {
    const { account, loadAccount, setUsername } = useWeb3();
    const {
        transactionLoading,
        previewModal,
        ratingModalState,
        lang,
        loadContract,
        loadFiles,
        loadAppOwner,
        loadActivities,
        loadAppProfits,
        getContractAbi,
        loadPromotionPrice,
        loadPaymentTokenPriceInUSD,
        loadFileBuyersWithBuyers,
    } = useFiles();
    const { fileView, setFileView } = useApp();
    const {
        streamContract,
        loadStreamContract,
        getStreamContractAbi,
        loadUserWatchlist,
        loadUserFiles,
        loadPaymentTokenAddress,
        loadPaymentTokenContract,
        paymentToken,
    } = useStream();
    const { chain } = useNetwork();
    const { i18n } = useTranslation();

    useEffect(() => {
        if (!localStorage.getItem('moviex_username')) {
            setUsername(
                uniqueNamesGenerator({
                    dictionaries: [starWars],
                    seed: account,
                }).replace('_', ' ')
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (streamContract && account) {
            loadAppProfits(streamContract, account, StreamAbi.abi);
            loadUserWatchlist(streamContract, account);
            loadUserFiles(streamContract, account);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [streamContract, account]);

    /*** -------------------------------------------- */
    //      GET PAYMENT TOKEN ADDRESS
    /*** -------------------------------------------- */
    useEffect(() => {
        if (paymentToken) {
            loadPaymentTokenContract(paymentToken, TokenAbi);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paymentToken]);

    /*** -------------------------------------------- */
    //      GET PAYMENT TOKEN CONTRACT
    /*** -------------------------------------------- */
    useEffect(() => {
        if (streamContract) {
            loadPaymentTokenAddress(streamContract);
            loadPromotionPrice(streamContract, StreamAbi.abi);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [streamContract]);

    /* -------------------------------------------------- */
    //      AOS ANIMATION
    /* -------------------------------------------------- */
    useEffect(() => {
        AOS.init({
            duration: 700,
            disable: 'mobile',
            once: true,
        });
    }, []);

    /* --------------------------------------------- 
          GET TOKEN PRICE IN USD
    --------------------------------------------- */
    useEffect(() => {
        loadPaymentTokenPriceInUSD(appSettings?.tokenId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appSettings?.tokenId]);

    useEffect(() => {
        /*** -------------------------------------------------- */
        //      GET BLOCKCHAIN DATA
        /*** -------------------------------------------------- */
        const calclateInitialSettings = async () => {
            getContractAbi(FilesAbi.abi);
            getStreamContractAbi(StreamAbi.abi);

            const acc = getAccount();
            loadAccount(acc?.address);

            // Load Contracts
            const fileDeployedNetwork = FilesAbi.networks[appSettings.networkId];
            const streamDeployedNetwork = StreamAbi.networks[appSettings.networkId];

            const filesContract =
                fileDeployedNetwork &&
                getContract({
                    address: fileDeployedNetwork.address,
                    abi: FilesAbi.abi,
                });

            const streamContract =
                streamDeployedNetwork &&
                getContract({
                    address: streamDeployedNetwork.address,
                    abi: StreamAbi.abi,
                });

            loadContract(filesContract);
            loadStreamContract(streamContract);

            loadFiles(filesContract, FilesAbi?.abi);
            loadFileBuyersWithBuyers(filesContract, FilesAbi?.abi);
            loadAppOwner(filesContract, FilesAbi?.abi);
            loadActivities(filesContract, FilesAbi?.abi);

            // eslint-disable-next-line no-unused-vars
            const unwatch = watchAccount((account) => {
                loadAccount(account?.address);
                setUsername(
                    uniqueNamesGenerator({
                        dictionaries: [starWars],
                    }).replace('_', ' ')
                );
            });
        };

        calclateInitialSettings();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /* --------------------------------------------- 
          CHANGE APP LANGUAGE
    --------------------------------------------- */
    useEffect(() => {
        i18n.changeLanguage(lang?.code);
    }, [lang, i18n]);

    return (
        <>
            <WagmiConfig client={wagmiClient}>
                <div className='app'>
                    <Header />
                    <ScrollToTop />
                    {<Outlet />}
                    <Footer />
                    <MobileNav />
                    {chain?.id !== appSettings.networkId && <ViewOnlyAlert />}
                </div>
                {ratingModalState && <Modal />}
                <ToastContainer position='top-center' autoClose={1500} />
                {previewModal && <PreviewModal />}
                {transactionLoading && <MetaMaskLoader />}
                {fileView && (
                    <Popup closeModal={() => setFileView(false)}>
                        <FilePreview />
                    </Popup>
                )}
            </WagmiConfig>
            <Web3Modal
                projectId={projectId}
                ethereumClient={ethereumClient}
                themeVariables={{
                    '--w3m-z-index': '9999',
                    '--w3m-container-background-color': 'rgba(0,0,0,.7)',
                }}
            />
        </>
    );
}

export default Layout;
