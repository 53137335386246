import React from 'react';
import { appSettings } from '../../helpers/settings';
import useApp from '../../hooks/useApp';
import { HiShieldCheck } from 'react-icons/hi';
import { Link } from 'react-router-dom';
import { FiTwitter, FiYoutube, FiInstagram } from 'react-icons/fi';
import { FaTelegram } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import androidapp from './download_google.7304f665.png';
import androidapk from './appapk.png';
function Footer() {
    const { themeMode } = useApp();
    const { t } = useTranslation();

    return (
        <footer className='main-footer pt-5'>
            <div className='container pt-5 pb-4'>
                <div className='row gy-4 gx-5 mb-4'>
                    <div className='col-xxl-3 col-lg-6'>
                        <img
                            src={`${themeMode === 'dark' ? appSettings?.logo : appSettings?.logoLight}`}
                            alt={`${appSettings?.brandName}`}
                            width={appSettings.logoWidth}
                            className='img-fluid mb-3'
                        />
                        <ul className='list-unstyled p-0 text-sm'>
                            <li className='d-flex mb-2'>
                                <HiShieldCheck className='text-primary me-2' size={20} />
                                <a href='https://difi.market' className='text-reset'>
                                    https://difi.market
                                </a>
                            </li>
                            <li>
                                <a
                                    rel='noopener noreferrer'
                                    target='_blank'
                                    href='https://play.google.com/store/apps/details?id=app.difi.market'
                                    className='text-reset text-sm mb-2'
                                >
                                    <img src={androidapp} alt="Google Play" width="120" />
                                </a>
                            </li>
	                            <li>
                                <a
                                    rel='noopener noreferrer'
                                    target='_blank'
                                    href='https://difi.market/DIFI.apk'
                                    className='text-reset text-sm mb-2'
                                >
                                    <img src={androidapk} alt="Android APK" width="120" />
                                </a>
                            </li>
							</ul>
                    </div>

                    <div className='col-xxl-3 col-lg-6'>
                        <h4 className='mb-3'>{t('sitemap')}</h4>
                        <ul className='list-unstyled mb-0 p-0 text-muted'>
                            <li className='mb-2'>
                                <Link className='text-reset text-sm' to='/'>
                                    {t('home')}
                                </Link>
                            </li>
                            <li className='mb-2'>
                                <Link className='text-reset text-sm' to='/discover'>
                                    {t('Discover')}
                                </Link>
                            </li>
                            <li className='mb-2'>
                                <Link className='text-reset text-sm' to='/Activities'>
                                    {t('activity')}
                                </Link>
                            </li>
                            <li className='mb-2'>
                                <Link className='text-reset text-sm' to='/about'>
                                    {t('aboutUs')}
                                </Link>
                            </li>
                            <li className='mb-2'>
                                <Link className='text-reset text-sm' to='/features-requests'>
                                    {t('contactUs')}
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className='col-xxl-3 col-lg-6'>
                        <h4 className='mb-3'>{t('resources')}</h4>
                        <ul className='list-unstyled mb-0 p-0 text-muted'>
                            <li>
                                <a
                                    rel='noopener noreferrer'
                                    target='_blank'
                                    href='https://pancakeswap.finance/swap?outputCurrency=0x0938a5d325A8496c186Cf122946e9dD22f8a625b'
                                    className='text-reset text-sm mb-2'
                                >
                                    {t('buy')} DIFI @ PancakeSwap
                                </a>
                            </li>
                            <li>
                                <a
                                    rel='noopener noreferrer'
                                    target='_blank'
                                    href='https://www.bitmart.com/trade/en?symbol=DIFI_USDT'
                                    className='text-reset text-sm mb-2'
                                >
                                    {t('buy')} DIFI @ Bitmart
                                </a>
                            </li>
                            <li>
                                <a
                                    rel='noopener noreferrer'
                                    target='_blank'
                                    href='https://dexscreener.com/bsc/0x0938a5d325A8496c186Cf122946e9dD22f8a625b'
                                    className='text-reset text-sm mb-2'
                                >
                                    DIFI {t('charts')}
                                </a>
                            </li>
                            <li>
                                <a
                                    rel='noopener noreferrer'
                                    target='_blank'
                                    href='https://bscscan.com/token/0x0938a5d325a8496c186cf122946e9dd22f8a625b'
                                    className='text-reset text-sm mb-2'
                                >
                                    DIFI @ BSCScan
                                </a>
                            </li>
                            <li>
                                <a
                                    rel='noopener noreferrer'
                                    target='_blank'
                                    href='https://coinmarketcap.com/currencies/digital-files/'
                                    className='text-reset text-sm mb-2'
                                >
                                    DIFI @ CoinmarketCap
                                </a>
                            </li>

                            <li>
                                <a
                                    rel='noopener noreferrer'
                                    target='_blank'
                                    href='https://www.coingecko.com/en/coins/digital-files'
                                    className='text-reset text-sm mb-2'
                                >
                                    DIFI @ CoinGecko
                                </a>
                            </li>

                            <li>
                                <a
                                    rel='noopener noreferrer'
                                    target='_blank'
                                    href='https://lottery.difi.market'
                                    className='text-reset text-sm mb-2'
                                >
                                    DIFI {t('lottery')}
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div className='col-xxl-3 col-lg-6'>
                        <h4 className='mb-3'>{t('menulegal')}</h4>
                        <ul className='list-unstyled mb-0 p-0 text-muted'>
                            <li>
                                <a
                                    rel='noopener noreferrer'
                                    target='_blank'
                                    href='https://difi.market/WhitePaper_difi.pdf'
                                    className='text-reset text-sm mb-2'
                                >
                                    {t('whitePaper')}
                                </a>
                            </li>
                            <li>
                                <a
                                    rel='noopener noreferrer'
                                    target='_blank'
                                    href='https://github.com/cyberscope-io/audits/blob/main/difi/audit.pdf'
                                    className='text-reset text-sm mb-2'
                                >
                                    {t('audit')}
                                </a>
                            </li>
                            <li>
                                <a
                                    rel='noopener noreferrer'
                                    target='_blank'
                                    href='https://difi.market/Privacy_Policy_DIFI.pdf'
                                    className='text-reset text-sm mb-2'
                                >
                                    {t('menuprivacy')}
                                </a>
                            </li>
                            <li>
                                <a
                                    rel='noopener noreferrer'
                                    target='_blank'
                                    href='https://difi.market/TOS_DIFI.pdf'
                                    className='text-reset text-sm mb-2'
                                >
                                    {t('menutos')}
                                </a>
                            </li>
                            <li>
                                <a
                                    rel='noopener noreferrer'
                                    target='_blank'
                                    href='https://difi.market/DIFI_Legal_Opinion.pdf'
                                    className='text-reset text-sm mb-2'
                                >
                                    {t('menulegaopinion')}
                                </a>
                            </li>
							</ul>
                    </div>
                </div>
                <h2 className='h3 text-center mb-1'>{t('weAreSocial')}</h2>
                <ul className='list-inline my-3 text-center'>
                    <li className='list-inline-item'>
                        <a
                            className='social-link bg-hover-primary'
                            rel='noreferrer'
                            href='https://twitter.com/difimarket'
							target='_blank'
							rel='noopener noreferrer'
                        >
                            <FiTwitter size='1.25rem' style={{ transform: 'translateY(-2px)' }} />
                        </a>
                    </li>
                    <li className='list-inline-item'>
                        <a
                            className='social-link bg-hover-primary'
                            rel='noreferrer'
                            href='https://instagram.com/difimarket'
							target='_blank'
							rel='noopener noreferrer'
                        >
                            <FiInstagram size='1.25rem' style={{ transform: 'translateY(-2px)' }} />
                        </a>
                    </li>
                    <li className='list-inline-item'>
                        <a className='social-link bg-hover-primary' rel='noreferrer' href='https://t.me/difitoken' target='_blank' rel='noopener noreferrer'>
                            <FaTelegram size='1.25rem' style={{ transform: 'translateY(-2px)' }} />
                        </a>
                    </li>
					                    <li className='list-inline-item'>
                        <a className='social-link bg-hover-primary' target='_blank' rel='noreferrer' href='https://www.youtube.com/@difimarket'>
                            <FiYoutube size='2rem' style={{ transform: 'translateY(-2px)' }} />
                        </a>
                    </li> 
                </ul>

                <p className='text-muted mb-0 text-center text-sm'>
                    {t('createdBy')}{' '}
                    <a href={appSettings.copyrightsLink} className='text-primary'>
                        {appSettings.copyrightsBrand}
                    </a>{' '}
                    {new Date().getFullYear()}. &copy; {t('copyRights')}
                </p>
            </div>
        </footer>
    );
}

export default Footer;
