/* ------------------------------------------------------------------------------------------ 
      ENGLISH TRANSLATIONS
------------------------------------------------------------------------------------------ */

export const vietnamTranslation = {
    recentlyAdded: 'Mới Thêm',
    topTrending: 'Đang Hot',
    featuredItems: 'Các File Nổi Bật',
    noCategoryMatch: 'Không có tệp nào phù hợp với danh mục này',
    noReviewsYet: 'Chưa có đánh giá',
    accessNow: 'Truy cập Ngay',
    downloadNow: 'Tải Xuống Ngay',
    accessFileFor: 'Truy cập tệp này chỉ với',
    youHaveAccess: 'Bạn đã có quyền truy cập vào tệp này',
    viewScreenshots: 'Xem Ảnh Chụp Màn Hình',
    promoted: 'Được Quảng Cáo',
    addToList: 'Thêm vào danh sách của tôi',
    inList: 'Trong danh sách của bạn',
    fileReviews: 'Đánh Giá Tệp',
    fileGallery: 'Thư Viện Tệp',
    pending: 'Đang Chờ',
    pendingMsg: 'Tệp này đang chờ phê duyệt từ quản trị viên',
    from: 'Từ',
    ratings: 'Đánh Giá',
    promoteThisFile: 'Quảng Cáo Tệp Này',
    promoteFor: 'Quảng cáo cho',
    youHaveReview: 'Bạn đã đánh giá tệp này',
    rateThisFile: 'Đánh Giá Tệp Này',
    fileHasNoReviews: 'Tệp này chưa có đánh giá',
    discoverAll: 'Khám Phá Tất Cả Tệp',
    filterByCategory: 'Lọc Theo Danh Mục',
    sortByDate: 'Sắp Xếp Theo Ngày',
    sortByPrice: 'Sắp Xếp Theo Giá',
    loadMore: 'Tải Thêm',
    select: 'Chọn',
    all: 'Tất Cả',
    noMatches: 'Không có tệp nào phù hợp với bộ lọc này',
    newest: 'Mới nhất đầu tiên',
    oldest: 'Cũ nhất đầu tiên',
    highPrice: 'Giá: Cao đến thấp',
    lowPrice: 'Giá: Thấp đến cao',
    headerLinks: 'Liên kết',
    headerDiscover: 'Khám phá',
    headerActivity: 'Hoạt động',
    headerFaqs: 'Câu hỏi thường gặp',
    headerContactUs: 'Liên hệ',
    trackTransaction: 'Theo dõi Giao dịch',
    headerMyAccount: 'Tài khoản của tôi',
    headerMyWishList: 'Danh sách mong muốn của tôi',
    headerAdminPanel: 'Bảng điều khiển Quản trị',
    headerAdminOnly: 'Chỉ dành cho quản trị viên',
    weAreSocial: 'Chúng tôi là mạng xã hội',
    createdBy: 'Được tạo bởi',
    copyRights: 'Đã đăng ký bản quyền',
    activityHeading: 'Hoạt động',
    tableActivityHeading: 'Hoạt động mới nhất',
    tableNoRecords: 'Chưa có hoạt động để hiển thị',
    tableUser: 'Người dùng',
    tableTime: 'Thời gian',
    tableAction: 'Hành động',
    tableMintFile: 'Tệp đã tải lên',
    tableApproveMint: 'Tệp đã phê duyệt',
    tableBuyFile: 'Tệp đã mua',
    tableWishList: 'Tệp được thêm vào danh sách theo dõi',
    tableNewSubscriber: 'Người đăng ký mới',
    tablePromote: 'Tệp đã được thêm vào khuyến mãi',
    contactPageHeading: 'Liên hệ với chúng tôi về bất kỳ vấn đề nào',
    contactPageSubHeading:
        'Điền vào biểu mẫu với những gì bạn cần và chúng tôi sẽ liên hệ với bạn qua email trong vòng 24 giờ',
    followUs: 'Theo dõi chúng tôi để cập nhật thông tin.',
    yourNameLabel: 'Họ và tên của bạn',
    yourNamePlaceholder: 'Nhập họ và tên của bạn',
    yourNameErrorMsg: 'Vui lòng nhập họ và tên của bạn',
    yourEmail: 'Địa chỉ email của bạn',
    yourEmailPlaceholder: 'Nhập địa chỉ email của bạn',
    yourEmailErrorMsg: 'Vui lòng nhập địa chỉ email hợp lệ',
    yourEmailInvalidPattern: 'Địa chỉ email không hợp lệ',
    yourMessage: 'Nội dung tin nhắn',
    yourMessagePlaceholder: 'Tin nhắn của bạn về bất kỳ yêu cầu nào',
    yourMessageErrorMsg: 'Vui lòng nhập nội dung tin nhắn của bạn',
    submitRequest: 'Gửi yêu cầu của bạn',
    submitting: 'Đang gửi...',
    successSubmitHeading: 'Cảm ơn bạn',
    successSubmitMsg: 'Chúng tôi sẽ liên hệ với bạn sớm nhất có thể',
    returnHome: 'Trở về Trang chủ',
    utility: 'Tiện ích',
    title: 'Tiêu đề',
    titleErrorMsg: 'Vui lòng nhập tiêu đề video của bạn',
    titlePlaceHolder: 'Nhập tiêu đề sản phẩm của bạn',
    description: 'Mô tả',
    descriptionErrorMsg: 'Vui lòng nhập mô tả tệp của bạn',
    descriptionPlaceholder: 'Thêm mô tả ngắn về tệp của bạn',
    category: 'Danh mục',
    categoryPlaceholder: 'Chọn một danh mục',
    compatibleWith: 'Tương thích với',
    compatibleWithPlaceholder: 'Chọn tương thích hệ điều hành',
    compatibleWithErrorMsg: 'Vui lòng chọn hệ điều hành mà phần mềm này tương thích',
    price: 'Giá',
    pricePlaceholder: 'Nhập giá mong muốn cho tệp của bạn',
    priceErrorMsg: 'Vui lòng nhập giá cho tệp của bạn',
    uploadFile: 'Tải lên tệp của bạn',
    uploadFileErrorMsg: 'Vui lòng tải lên tệp của bạn',
    uploadFileThumb: 'Tải lên hình thu nhỏ của tệp',
    uploadFileThumbErrorMsg: 'Vui lòng tải lên hình thu nhỏ của tệp',
    uploadFileScreenshots: 'Tải lên Ảnh chụp màn hình của tệp',
    uploadFileScreenshotsErrorMsg: 'Vui lòng tải lên ảnh chụp màn hình của tệp của bạn',
    wishlistPageHeading: 'Danh sách mong muốn của tôi',
    myAccountHeading: 'Tài khoản của tôi',
    sellNewProduct: 'Bán Sản phẩm mới',
    salesProfits: 'Lợi nhuận từ Bán hàng',
    noProfitsYet: 'Bạn chưa có bất kỳ lợi nhuận nào',
    claimProfits: 'Yêu cầu lợi nhuận',
    accTableFile: 'Tệp',
    accTableCreator: 'Người tạo',
    accTableCreatedAt: 'Được tạo lúc',
    filesIHaveUploaded: 'Tệp Tôi đã tải lên',
    accNoUploadedFiles: 'Chưa có tệp được tải lên để hiển thị',
    accPendingFiles: 'Tệp đang chờ duyệt của tôi',
    accNoPendingFiles: 'Chưa có tệp đang chờ duyệt để hiển thị',
    accPurchasedFiles: 'Tệp đã mua của tôi',
    accNoPurchasedFiles: 'Chưa có tệp đã mua để hiển thị',
    adminPanel: 'Bảng điều khiển Quản trị',
    appProfits: 'Lợi nhuận từ Ứng dụng',
    approveSelected: 'Duyệt Lựa chọn',
    blockSelected: 'Chặn Lựa chọn',
    noFilesToDisplay: 'Không có tệp để hiển thị',
    allPendingFiles: 'Tất cả các tệp đang chờ duyệt',
    status: 'Trạng thái',
    active: 'Hoạt động',
    setPromotionPrice: 'Đặt Giá khuyến mãi',
    currentPriceIs: 'Giá hiện tại là',
    promotionPrice: 'Giá khuyến mãi',
    promotionPricePlaceholder: 'Nhập giá khuyến mãi',
    promotionPriceErrorMsg: 'Vui lòng nhập giá khuyến mãi',
    setPrice: 'Đặt Giá',
    transferContractOwnership: 'Chuyển Quyền Sở hữu Hợp đồng',
    newOwner: 'Chủ sở hữu mới',
    newOwnerPlaceholder: 'Nhập địa chỉ chủ sở hữu mới',
    newOwnerErrorMsg: 'Địa chỉ không hợp lệ! Vui lòng kiểm tra địa chỉ bạn đã nhập',
    transfer: 'Chuyển nhượng',
    newOwnerSuccessHeading: 'Bạn không còn là chủ sở hữu của hợp đồng này',
    newOwnerSuccessText:
        'Quyền sở hữu đã được chuyển cho một tài khoản khác, bạn không còn quyền truy cập vào trang này. Chúc bạn may mắn',
    updatePrice: 'Cập nhật Giá',
    newPrice: 'Giá mới',
    updateProductPrice: 'Cập nhật Giá sản phẩm',
    equals: 'Bằng',
    txLoadingHeading: 'Quá trình này thường mất một chút thời gian',
    txLoadingMsg: 'Chúng tôi đang xử lý giao dịch của bạn, vui lòng không làm mới trình duyệt',
    txLoadingSmText: 'Đang xử lý Giao dịch',
    rating: 'Đánh giá',
    reason: 'Lý do',
    enterRatingReason: 'Vui lòng nhập lý do đánh giá',
    reasonPleaceholder: "'ví dụ: Chất lượng Code'",
    message: 'Tin nhắn',
    messagePlaceholder: 'Nhập tin nhắn đánh giá của bạn',
    ratingMsgError: 'Vui lòng nhập tin nhắn đánh giá',
    submitRating: 'Gửi đánh giá của bạn',
    close: 'Đóng',
    uploadingYourAsset: 'Đang tải lên tài sản của bạn',
    ipfsMsg: 'Điều này có thể mất một thời gian cho đến khi chúng tôi lưu tài sản của bạn vào IFPS...',
    cover: 'Bìa',
    avatar: 'Ảnh đại diện',
    screenshot: 'Ảnh chụp màn hình',
    appRunOn: 'Ứng dụng này được đặt để chạy trên',
    connectToNetwork: 'Vui lòng kết nối với mạng này',
    switchNetwork: 'Chuyển đổi Mạng',
    oneOfYourItem: 'Một trong các Mục của bạn',
    err: 'Oops! Đã xảy ra lỗi',
    youHaveAccessNow: 'Tuyệt vời! Bạn đã có quyền truy cập ngay bây giờ',
    fillTheFields: 'Vui lòng điền vào các trường để có thể gửi',
    addedToWatchlist: 'Tuyệt vời! Bạn đã thêm tệp này vào danh sách theo dõi của mình',
    claimedProfits: 'Tuyệt vời! Bạn đã yêu cầu lợi nhuận của mình',
    filePriceUpdated: 'Tuyệt vời! Giá tệp của bạn đã được cập nhật',
    approvedSelectedFiles: 'Tuyệt vời! Bạn đã phê duyệt các Tệp đã chọn',
    blockedSelectedFiles: 'Tuyệt vời! Bạn đã chặn các Tệp đã chọn',
    promotionPriceAdded: 'Tuyệt vời! Giá khuyến mãi đã được đặt',
    ownerShipChanged: 'Tuyệt vời! Quyền sở hữu đã được chuyển cho một tài khoản khác',
    fileIsPromoted: 'Tuyệt vời! Tệp của bạn đã được quảng cáo',
    fileUploaded: 'Tuyệt vời! Bạn đã tải lên tệp thành công',
    filesTooLarge: 'Tệp quá lớn',
    buy: 'Mua',
    charts: 'Biểu đồ',
    audit: 'Kiểm toán',
    lottery: 'Xổ số',
    frequAskedQuestions: 'Câu hỏi Thường gặp',
    whoAreWe: 'Chúng tôi là ai?',
    whoWeAreAnsw:
        'Chúng tôi là một nhóm nhà phát triển Web3, đang thực hiện những gì sẽ là tương lai của internet, blockchain và phi tập trung. Dự án này cho phép mỗi người dùng bán tệp của họ một cách an toàn, trực tiếp từ máy tính hoặc điện thoại thông minh của họ, mà không cần bên thứ ba.',
    maxTotalSupply: 'Số lượng Tối đa/Toàn bộ',
    assetPipeLine: 'và đường ống tài sản cho các blockchain.',
    tax: 'Thuế',
    sell: 'Bán',
    holderRewards: 'Phần thưởng cho người nắm giữ',
    marketing: 'Tiếp thị',
    whitePaper: 'Báo cáo trắng',
    trade: 'Giao dịch',
    official: 'Chính thức',
    clickHere: 'Nhấp vào đây',
    difiDetailsQuest: 'Chi tiết và Liên kết DIFI',
    functionsOfDifiMarket: 'Chức năng của thị trường DIFI?',
    onDifiMarketYouCan: 'Trên thị trường DIFI, bạn có thể',
    difiMarketBullet1: 'Bán các tệp, chương trình và bất kỳ loại tệp số nào.',
    difiMarketBullet2: 'Mua các Tệp của người dùng khác.',
    difiMarketBullet3: 'Để lại phản hồi sau khi mua hàng.',
    difiMarketBullet4: 'Rút DIFI từ doanh số bán hàng.',
    whatWalletSupportedQues: 'Các ví nào được hỗ trợ?',
    whatWalletSupportedAnsw:
        'Digital Files sử dụng WalletConnect và hỗ trợ hàng chục ví khác nhau để tương tác với Cửa hàng.',
    whatAreCommissionQuest: 'Các khoản phí là gì?',
    fees: 'Phí',
    onEachSale: 'trên mỗi bán hàng',
    whatAreCommissionAnsw:
        'Một khoản thuế 1% được áp dụng cho mỗi tệp được bán, hệ thống sử dụng blockchain và IPFS, và 1% thuế trên mỗi giao dịch cho phép chúng tôi duy trì hoạt động của hệ thống một cách hoàn hảo.',
    uploadLimit: 'Giới hạn tải lên?',
    uploadLimitAnsw1: 'Giới hạn tải lên tối đa cho mỗi tệp là khoảng 100MB',
    uploadLimitAnsw2: 'Không có giới hạn về số lượng tệp mà người dùng có thể tải lên và bán.',
    whatDoIBuyWith: 'Tôi mua Tệp bằng gì?',
    whatDoIBuyWithAnsw:
        'Các tệp có thể được mua bằng Token DIFI, thậm chí cần một số BNB để quản lý các giao dịch vì nó là đơn vị tiền tệ chính.',
    canIChangePrice: 'Tôi có thể thay đổi giá của một Tệp không?',
    canIChangePriceAnsw:
        'Hiện tại không thể thay đổi giá của một tệp, hãy tính toán đúng dựa trên giá của DIFI khi bạn bán một tệp.',
    moreQuestions: 'Câu hỏi khác?',
    ifYouCantFindAnsw: 'Nếu bạn không thể tìm thấy câu trả lời ở đây',
    getInTouch: 'liên hệ',
    weWillHelp: 'chúng tôi sẽ rất vui lòng giúp bạn',
    education: 'Tài liệu giáo dục',
    eBooks: 'eBooks',
    gDesign: 'Thiết kế đồ họa',
    os: 'Hệ điều hành',
    programming: 'Lập trình',
    antiVirus: 'Phần mềm diệt virus và bảo mật',
    db: 'Cơ sở dữ liệu',
    mutimedia: 'Đa phương tiện',
    gaming: 'Trò chơi',
    openSource: 'Mã nguồn mở',
    adult: 'Người lớn 18+',

    // Latest Translations keys --------------
    faqs: 'Câu hỏi thường gặp',
    contactUs: 'Liên hệ chúng tôi',
    aboutUs: 'Về chúng tôi',
    activity: 'Hoạt động',
    aboutUsHeading: 'Về chúng tôi',
    wordsAboutUsHeading: 'Một số từ về',
    appLauncingTime: 'DIFI ra mắt vào ngày 1 tháng 4 năm 2023',
    aboutWord1:
        'Thị trường Tệp số đầu tiên được tạo ra trên Blockchain trong web3, được hỗ trợ bởi Binance Smart Chain.',
    aboutWord2:
        'Mua, bán và quảng bá sáng tạo số của bạn một cách dễ dàng và an toàn nhất trên thị trường. Không cần đăng ký, không có bên thứ ba liên quan. Đây là Sự phi tập trung DIFI.',
    creator: 'Người tạo',
    topFeatureHeading: 'Các tính năng hàng đầu',
    toPFeatureSubheading: 'Top 3 tính năng trong DIFI Market không thể sánh bằng!',
    decentralized: 'Phi tập trung',
    decentralizedDescription:
        'DIFI Market là 100% Phi tập trung, mọi thứ được lưu trữ trên Binance Smart Chain để đảm bảo an ninh tối đa, Người dùng tương tác bằng cách kết nối Ví của họ mà không cần quản lý bên ngoài.',
    hackerFreeHeading: 'Không bị Hacker xâm nhập',
    hackerFreeDescription:
        'Hệ thống không lưu trữ bất cứ thông tin nào trên máy chủ web, không có dữ liệu có nguy cơ, người dùng tương tác với ví của họ khi kết nối, không có gì có thể bị can thiệp, và các quản trị viên không có quyền truy cập vào quỹ người dùng.',
    lowFeesHeading: 'Phí thấp',
    lowFeesDescription:
        'Có lẽ là phí thấp nhất toàn cầu, chỉ 1% phí hoa hồng trên doanh số bán hàng, không có phí ẩn khác, người dùng có thể rút DIFI từ tài khoản của họ bất cứ lúc nào, không cần chờ đợi hay xác nhận, người dùng chỉ trả bằng DIFI vào thời điểm đặt hàng.',
    aboutUsQuote: '"Với DIFI Market, chúng ta đã ở trong tương lai, trước hết."',
    token: 'Token',
    founder: 'Người sáng lập',
    digitalFilesMarket: 'Thị trường Tệp số',
    subscribeThanks: 'Cảm ơn bạn đã đăng ký',
    toastError: 'Oops! Đã xảy ra lỗi!',
    resources: 'Tài nguyên',
    newsletterSignup: 'Đăng ký nhận bản tin',
    newsletterText: 'Đăng ký nhận bản tin và cập nhật thông tin',
    newsletterErrMsg: 'Vui lòng nhập địa chỉ email của bạn',
    newsletterPlaceholder: 'Thêm email của bạn',
    sitemap: 'Sơ đồ trang web',
    bestSellers: 'Bán chạy nhất',
    topRated: 'Được đánh giá cao nhất',
    home: 'Trang chủ',
    files: 'Tệp',
    resetFilters: 'Đặt lại bộ lọc',
    searchForFiles: 'Tìm kiếm sản phẩm',
    noSearchResults: 'Không có sản phẩm phù hợp với tìm kiếm của bạn',
    noFilesAtTheMoment: 'Hiện tại không có tệp nào.',
    emptyHere: 'Ở đây rỗng rồi',
    poromotionEndsAt: 'Khuyến mãi kết thúc vào',
    sortByRate: 'Sắp xếp theo Đánh giá',
    shopBrandDescription: 'Cửa hàng tệp số đa trung tâm phi tập trung Web3',
    uploadFileNav: 'Tải tệp lên',
    account: 'Tài khoản',
    wishlist: 'Danh sách mong muốn',
    upload: 'Tải lên',
    menulegal: 'Pháp lý',
    menuprivacy: 'Chính sách bảo mật',
    menutos: 'Điều khoản dịch vụ',
    menulegaopinion: 'Ý kiến pháp lý',
    share: 'Chia sẻ',
};
