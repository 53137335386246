import React from 'react';

const AppContext = React.createContext({
    themeMode: localStorage.getItem('digital_files_theme') || 'light',
    fileView: false,
    fileViewSrc: {},
    switchMode: () => {},
    setFileView: () => {},
    setFileViewSrc: () => {},
});

export default AppContext;
