import React, { useState, useEffect, useMemo } from 'react';
import { BsFillCalendar2MinusFill } from 'react-icons/bs';
import { MdComputer, MdReviews } from 'react-icons/md';
import { FaCloudDownloadAlt } from 'react-icons/fa';
import { formatSimpleDate, formatDate } from '../../helpers/utils';
import useFiles from '../../hooks/useFiles';
import useWeb3 from '../../hooks/useWeb3';
import useStream from '../../hooks/useStream';
import { Jazzicon } from '@ukstv/jazzicon-react';
import { toast } from 'react-toastify';
import WatchListAction from '../../components/general/WatchListAction';
// import Viewer from 'react-viewer';
import { appSettings } from '../../helpers/settings';
import Web3 from 'web3';
import ConnectWalletHandler from '../../components/general/ConnectWalletHandler';
import RatingDisplay from '../../components/general/RatingDisplay';
import { useContractWrite } from 'wagmi';
import { waitForTransaction } from '@wagmi/core';
import TokenAbi from '../../integration/TokenAbi.json';
import { useTranslation } from 'react-i18next';
import FileGallery from './FileGallery';

import {
    EmailShareButton,
    EmailIcon,
    FacebookShareButton,
    FacebookIcon,
    PinterestShareButton,
    PinterestIcon,
    TelegramShareButton,
    TelegramIcon,
    TwitterShareButton,
    TwitterIcon,
    WhatsappShareButton,
    WhatsappIcon,
} from 'react-share';

function Hero({ createdAt, metadata, creator, id, approved, reviews, promoted, usdPrice, promotingExp, tokenPrice }) {
    const {
        contract,
        filesContractAbi,
        files,
        promotionPrice,
        usdToken,
        setRatingModalState,
        setRatingModalSrc,
        loadFileBuyers,
        loadFiles,
        setTransactionLoading,
        owner,
    } = useFiles();
    const { streamContract, streamAbi, paymentToken } = useStream();
    const { account } = useWeb3();
    const [fileBuyers, setFileBuyers] = useState(null);
    const [isSubscriber, setIsSubscriber] = useState(false);
    const [approvedTx, setApprovedTx] = useState(null);
    const [approvedPromoteTx, setApprovedPromoteTx] = useState(null);
    const { t } = useTranslation();

    const promotionExpiration = useMemo(() => {
        if (promotingExp > 0) {
            return formatDate(promotingExp);
        } else {
            return 0;
        }
    }, [promotingExp]);

    /* --------------------------------------------- 
              BUY FILE FUNCTION
     --------------------------------------------- */
    const { write: web3BuyFile } = useContractWrite({
        address: streamContract?.address,
        abi: streamAbi,
        functionName: 'buyFile',
        args: [Number(id), Web3.utils.toWei(tokenPrice.toString(), 'ether')],
        onSuccess() {
            setTimeout(() => {
                setTransactionLoading(false);
                loadFiles(contract, filesContractAbi);
                toast.success(t('youHaveAccessNow'));
            }, 5000);
        },
        onMutate() {
            setTransactionLoading(true);
        },
        onError(error) {
            setTransactionLoading(false);
            toast.error(t('err'));
        },
    });

    /* --------------------------------------------- 
              APPROVE SENDING TOKEN
     --------------------------------------------- */
    const { write: web3ApproveTransfer, data: txData } = useContractWrite({
        address: paymentToken,
        abi: TokenAbi,
        functionName: 'approve',

        onSuccess() {
            setTransactionLoading(true);
        },
        onMutate() {
            setTransactionLoading(true);
        },
        onError(error) {
            setTransactionLoading(false);
            toast.error(t('err'));
        },
    });

    /* --------------------------------------------- 
              APPROVE PROMOTING FILE
     --------------------------------------------- */
    const { write: web3ApprovePromoting, data: txPromotingData } = useContractWrite({
        address: paymentToken,
        abi: TokenAbi,
        functionName: 'approve',

        onSuccess() {
            setTransactionLoading(true);
        },
        onMutate() {
            setTransactionLoading(true);
        },
        onError(error) {
            setTransactionLoading(false);
            toast.error(t('err'));
        },
    });

    /* --------------------------------------------- 
              PROMOTE FILE FUNCTION
     --------------------------------------------- */
    const { write: web3PromoteFile } = useContractWrite({
        address: streamContract?.address,
        abi: streamAbi,
        functionName: 'promote',
        args: [Number(id), Web3.utils.toWei(Number(Math.round(promotionPrice * usdToken)).toString(), 'ether')],
        onSuccess() {
            setTimeout(() => {
                setTransactionLoading(false);
                loadFiles(contract, filesContractAbi);
                toast.success(t('fileIsPromoted'));
            }, 5000);
        },
        onMutate() {
            setTransactionLoading(true);
        },
        onError(error) {
            setTransactionLoading(false);
            toast.error(t('err'));
        },
    });

    useEffect(() => {
        if (txData) {
            async function getTx() {
                const waitFrTx = await waitForTransaction({
                    hash: txData?.hash,
                });
                setApprovedTx(waitFrTx);
            }

            getTx();
        }
    }, [txData]);

    useEffect(() => {
        if (txPromotingData) {
            async function getPromtionTx() {
                const waitFrTx = await waitForTransaction({
                    hash: txPromotingData?.hash,
                });
                setApprovedPromoteTx(waitFrTx);
            }

            getPromtionTx();
        }
    }, [txPromotingData]);

    useEffect(() => {
        if (approvedTx) {
            web3BuyFile();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [approvedTx]);

    useEffect(() => {
        if (approvedPromoteTx) {
            web3PromoteFile();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [approvedPromoteTx]);

    function BuyFileHandler() {
        web3ApproveTransfer({
            recklesslySetUnpreparedArgs: [streamContract.address, Web3.utils.toWei(tokenPrice.toString(), 'ether')],
        });
    }

    function promoteFileHandler() {
        web3ApprovePromoting({
            recklesslySetUnpreparedArgs: [
                streamContract.address,
                Web3.utils.toWei(Number(Math.round(promotionPrice * usdToken)).toString(), 'ether'),
            ],
        });
    }

    /* --------------------------------------------- 
          LOAD FILE BUYERS
    --------------------------------------------- */
    useEffect(() => {
        if (contract && files.length > 0) {
            async function getFileBuyers() {
                const subs = await loadFileBuyers(contract, Number(id), filesContractAbi);
                const formattedSubs = subs?.map((subscriber) => subscriber[0]);
                setFileBuyers([...new Set(formattedSubs)]);
            }
            getFileBuyers();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contract, files]);

    /* --------------------------------------------- 
        CHECK IF CONNECTED ACCOUNT IS SUBSCRIBER
    --------------------------------------------- */
    useEffect(() => {
        if (account && contract) {
            const checkSubscriber = fileBuyers?.includes(account);
            setIsSubscriber(checkSubscriber);
        }
    }, [account, contract, fileBuyers]);

    /* --------------------------------------------- 
          CALCULATE STARS
    --------------------------------------------- */
    const totalStars = reviews
        ?.map((rev) => rev?.stars)
        ?.reduce((total, star) => {
            return total + star;
        }, 0);

    const avgStars = totalStars / reviews?.length;

    return (
        <>
            <div className='hero-slide file-single-hero pt-5 overflow-hidden'>
                <div className='container z-index-20 py-5 mt-5'>
                    <div className='row g-5'>
                        <div className='col-lg-6'>
                            <FileGallery gallery={[metadata?.thumbnail, ...metadata?.screenshots]} />
                        </div>
                        <div className='col-lg-6'>
                            <ul className='list-inline' data-aos='fade-up'>
                                <li className='list-inline-item'>
                                    <div className='badge bg-green fw-normal rounded-0'>
                                        {t(
                                            `${
                                                appSettings.categoryOptions?.filter(
                                                    (ct) => ct?.value === metadata.category
                                                )[0]?.translationKey
                                            }`
                                        )}
                                    </div>
                                </li>
                            </ul>

                            <h2 className='h1 text-xxl text-shadow' data-aos='fade-up' data-aos-delay='100'>
                                {metadata.title}
                            </h2>
                            <div className='d-block' data-aos='fade-up' data-aos-delay='300'>
                                {approved || (
                                    <div className='bg-danger px-2 text-white d-inline-block mb-4 fw-light'>
                                        <strong className='me-2 headings-font-family'>{t('pending')}!</strong>
                                        {t('pendingMsg')}
                                    </div>
                                )}
                            </div>
                            <ul className='list-inline justify-content-center' data-aos='fade-up' data-aos-delay='200'>
                                <li className='list-inline-item'>
                                    <span className='small ms-2'>
                                        <BsFillCalendar2MinusFill className='text-warning me-2' size='1.2rem' />{' '}
                                        {formatSimpleDate(createdAt)}
                                    </span>
                                </li>
                                <li className='list-inline-item ms-2 lh-1' style={{ fontSize: '0.7rem' }}>
                                    |
                                </li>
                                <li className='list-inline-item'>
                                    <span className='small ms-2'>
                                        <MdComputer className='text-warning me-2' size='1.4rem' />{' '}
                                        {metadata.compatibility}
                                    </span>
                                </li>
                            </ul>
                            <p className='text-gray-500 lead fw-light mb-4' data-aos='fade-up' data-aos-delay='300'>
                                {metadata.description}
                            </p>
                            <div data-aos='fade-up' data-aos-delay='350'>
                                {reviews?.length > 0 ? (
                                    <div className='mb-4 d-flex align-items-center'>
                                        <div className='mx-1'>
                                            <RatingDisplay rating={Math.ceil(avgStars)} />
                                        </div>
                                        <span className='mx-1'>{avgStars.toFixed(1)}</span>
                                        <p className='mb-0 mx-1 text-muted'>
                                            {t('from')}{' '}
                                            <strong className='fw-bold text-white'>{reviews?.length}</strong>{' '}
                                            {t('ratings')}
                                        </p>
                                    </div>
                                ) : (
                                    <p>{t('noReviewsYet')}</p>
                                )}
                            </div>
                            {account ? (
                                <ul className='list-inline p-0 mb-0' data-aos='fade-up' data-aos-delay='400'>
                                    {isSubscriber || creator === account || owner === account ? (
                                        <li className='list-inline-item me-2'>
                                            <a href={metadata.file} className='btn btn-primary'>
                                                <FaCloudDownloadAlt className='mb-1 me-2' size='1.35rem' />
                                                {t('downloadNow')}
                                            </a>
                                        </li>
                                    ) : (
                                        <li className='list-inline-item me-2'>
                                            <button type='button' className='btn btn-primary' onClick={BuyFileHandler}>
                                                <FaCloudDownloadAlt className='mb-1 me-2' size='1.35rem' />
                                                {t('accessNow')}
                                            </button>
                                        </li>
                                    )}
                                    <WatchListAction id={id} creator={creator} />
                                </ul>
                            ) : (
                                <ConnectWalletHandler customClass='my-3' />
                            )}

                            {promoted && (
                                <div className='py-2'>
                                    <span className='badge bg-success px-4'>{t('promoted')}</span>
                                    <br />
                                    {account === creator && (
                                        <div className='d-inline-block'>
                                            <p className='mt-2 btn-opac-secondary px-3 py-1 text-xxs rounded cursor-default'>
                                                {t('poromotionEndsAt')} {promotionExpiration}
                                            </p>
                                        </div>
                                    )}
                                </div>
                            )}

                            {!promoted && creator === account && promotionPrice > 0 && (
                                <>
                                    <button
                                        className='btn btn-success mt-2'
                                        data-aos='fade-up'
                                        data-aos-delay='550'
                                        onClick={promoteFileHandler}
                                    >
                                        {t('promoteThisFile')}
                                    </button>
                                    <p
                                        className='text-center small mt-1 d-flex align-items-center'
                                        data-aos='fade-up'
                                        data-aos-delay='550'
                                    >
                                        {t('promoteFor')}{' '}
                                        <span className='text-success ms-1'>{promotionPrice} USD</span>
                                    </p>
                                </>
                            )}

                            {isSubscriber ? (
                                creator !== account &&
                                (reviews?.map((rev) => rev.account).includes(account) ? (
                                    <div className='mt-3' data-aos='fade-up' data-aos-delay='550'>
                                        {t('youHaveReview')}
                                        <RatingDisplay
                                            rating={reviews?.filter((rev) => rev.account === account)[0]?.stars}
                                        />
                                    </div>
                                ) : (
                                    <button
                                        className='btn btn-warning'
                                        onClick={() => {
                                            setRatingModalState(true);
                                            setRatingModalSrc(id, metadata.title);
                                        }}
                                        data-aos='fade-up'
                                        data-aos-delay='550'
                                    >
                                        <MdReviews className='mb-1 me-2' size='1.35rem' />
                                        {t('rateThisFile')}
                                    </button>
                                ))
                            ) : (
                                <p
                                    className='small d-flex align-items-center mt-2'
                                    data-aos='fade-up'
                                    data-aos-delay='500'
                                >
                                    {t('accessFileFor')} <span className='text-primary ms-2'>{usdPrice} USD</span>{' '}
                                    <span className='mx-1'>-</span>
                                    <img src='/token.png' alt='DIFI' width='20' className='me-1' /> {tokenPrice} DIFI
                                </p>
                            )}
                            {fileBuyers && (
                                <>
                                    <ul className='list-unstyled avatars mt-4' data-aos='fade-up' data-aos-delay='400'>
                                        {fileBuyers.slice(0, 3).map((subscriber, i) => {
                                            return (
                                                <li className='avatar avatar-md2' key={i}>
                                                    <div style={{ width: '40px', height: '40px' }}>
                                                        <Jazzicon address={subscriber || ''} />
                                                    </div>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </>
                            )}

                            <div className='mt-4' data-aos='fade-up'>
                                <h5>{t('share')}</h5>
                                <ul className='list-inline p-0 mb-0'>
                                    <li className='list-inline-item'>
                                        <EmailShareButton
                                            subject={`${metadata?.title}`}
                                            url={`https://difi.market/files/${id}`}
                                            body={`${metadata?.description}`}
                                            separator=' '
                                        >
                                            <EmailIcon size={26} round={true} />
                                        </EmailShareButton>
                                    </li>
                                    <li className='list-inline-item'>
                                        <TwitterShareButton
                                            url={`https://difi.market/files/${id}`}
                                            title={`${metadata?.title}`}
                                            hashtags={['difi_market']}
                                        >
                                            <TwitterIcon size={26} round={true} />
                                        </TwitterShareButton>
                                    </li>
                                    <li className='list-inline-item'>
                                        <FacebookShareButton
                                            url={`https://difi.market/files/${id}`}
                                            description={`${metadata?.description}`}
                                        >
                                            <FacebookIcon size={26} round={true} />
                                        </FacebookShareButton>
                                    </li>
                                    <li className='list-inline-item'>
                                        <WhatsappShareButton
                                            url={`https://difi.market/files/${id}`}
                                            title={metadata?.title}
                                            separator=' - '
                                        >
                                            <WhatsappIcon size={26} round={true} />
                                        </WhatsappShareButton>
                                    </li>
                                    <li className='list-inline-item'>
                                        <PinterestShareButton
                                            url={`https://difi.market/files/${id}`}
                                            media={`${metadata?.thumbnail}`}
                                            description={`${metadata?.description}`}
                                        >
                                            <PinterestIcon size={26} round={true} />
                                        </PinterestShareButton>
                                    </li>
                                    <li className='list-inline-item'>
                                        <TelegramShareButton
                                            url={`https://difi.market/files/${id}`}
                                            title={`${metadata?.title}`}
                                        >
                                            <TelegramIcon size={26} round={true} />
                                        </TelegramShareButton>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* {isOpen && (
                <Viewer
                    visible={isOpen}
                    activeIndex={activeIndex}
                    onClose={() => {
                        setIsOpen(false);
                    }}
                    images={images}
                />
            )} */}
        </>
    );
}

export default Hero;
